import { useEffect, useState } from "react";
import { SimpleSpinner } from "../elements/Spinner";
import { SearchCompanyLoader } from "src/pages/toolbar/SearchResults";
import Pagination from "../elements/Pagination";
import {
  usePreCuratedCompanies,
  usePreCuratedListDetail,
} from "src/hooks/usePreCuratedCompanies";
import { ListDetail } from "./ListDetail";
import { useListFilterQuery } from "src/hooks/useSearchQuery";

export function ListView() {
  const [chatCreationLoading, setChatCreationLoading] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const detailsQuery = usePreCuratedListDetail();

  const listFilter = useListFilterQuery();
  const stringifiedFilter = JSON.stringify(listFilter);
  useEffect(() => {
    setPageNum(1);
  }, [stringifiedFilter]);

  const { isLoading, error, data, refetch } = usePreCuratedCompanies({
    pageNum: pageNum,
    listFilter: listFilter,
  });

  // console.log({ listDetailsData: data });

  // console.log(data);
  if (detailsQuery.isLoading || chatCreationLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <SimpleSpinner radius={20} overrideClasses="border-black" />
      </div>
    );
  }
  if (error) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <button
          onClick={() => {
            refetch();
          }}
        >
          Error fetching companies. <strong>Retry?</strong>
        </button>
      </div>
    );
  }
  const dataLength = data?.meta_data?.[0]?.count || 0;
  const totalPages = Math.ceil(dataLength / 100);
  const hasNextPage = pageNum < totalPages;
  const handleNextPage = () => {
    if (hasNextPage) {
      setPageNum(pageNum + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pageNum > 1) {
      setPageNum(pageNum - 1);
    }
  };

  return (
    <div className="grid h-full grid-rows-[min-content,1fr,40px]">
      <ListDetail
        detailsQuery={detailsQuery}
        setChatCreationLoading={setChatCreationLoading}
      />

      <div className="h-full w-full overflow-auto">
        <SearchCompanyLoader
          isLoading={isLoading}
          error={error}
          height="full"
          compact={false}
          insideChat={false}
          refetch={refetch}
          searchCompanies={data?.companies || []}
        />
      </div>

      <div className="flex justify-center pb-1 md:justify-end md:p-2 md:pb-1">
        <Pagination
          currentPage={pageNum}
          hasNextPage={(dataLength || 0) > pageNum * 100}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
}
