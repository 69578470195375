import * as React from "react";
const CountryIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.368 4.79005L5.632 3.87705C5.33133 3.77691 5.01118 3.74964 4.69791 3.79751C4.38464 3.84538 4.08723 3.967 3.83019 4.15236C3.57315 4.33772 3.36383 4.58151 3.21949 4.86363C3.07516 5.14576 2.99992 5.45814 3 5.77505V17.558C2.9999 17.978 3.13198 18.3873 3.37752 18.7279C3.62305 19.0686 3.96959 19.3233 4.368 19.456L8.368 20.789C8.77825 20.9257 9.22175 20.9257 9.632 20.789L14.368 19.211C14.7786 19.0742 15.2224 19.0742 15.633 19.211L18.368 20.123C18.6687 20.2232 18.9888 20.2504 19.3021 20.2026C19.6154 20.1547 19.9128 20.0331 20.1698 19.8477C20.4269 19.6624 20.6362 19.4186 20.7805 19.1365C20.9248 18.8543 21.0001 18.542 21 18.225V6.44205C21.0002 6.02224 20.8682 5.61302 20.6229 5.27237C20.3775 4.93172 20.0312 4.67692 19.633 4.54405L15.633 3.21105C15.2224 3.07418 14.7786 3.07418 14.368 3.21105L9.631 4.78905C9.22074 4.92569 8.77725 4.92569 8.367 4.78905"
      stroke="#111010"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 5V21M15 3V19"
      stroke="#111010"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
  </svg>
);
export default CountryIcon;
