import { useAuth } from "../../context/AuthContext";
import { ProfilePicture } from "../../components/elements/ProfilePicture";
import { useEffect, useState } from "react";
import InputBox from "./InputBox";
import { RocketIcon } from "src/components/svgs/RocketIcon";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import backend_services from "src/services/backend_service";
import Spinner from "src/components/elements/Spinner";
import GoogleIcon from "src/components/svgs/Google";
import EmailIcon from "src/components/svgs/Email";
import MicrosoftIcon from "src/components/svgs/Microsoft";
import { GoogleProvider, LinkPassword, LinkProvider, MicrosoftProvider, PasswordProvider } from "./AccountProvider";

export default function AccountPage() {
  const { user, signOut } = useAuth();
  const navigator = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [company, setCompany] = useState("");
  const [googleProvider, setgoogleProvider] = useState(false);
  const [passwordProvider, setPasswordProvider] = useState(false);
  const [microsoftProvider, setMicrosoftProvider] = useState(false);
  const [profileLoading, setProfileLoading] = useState(true);
  const [providerLoading, setProviderLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [provider, setProvider] = useState("google.com");

  useEffect(() => {
    const fetchData = async () => {
      const result = (user && await backend_services
        .fetchProtectedData(`/user/profile`, user.getIdToken())
        .then((res) => {
          return res.json();
        }))
      if (result) {
        setFirstName(result.first_name || "");
        setLastName(result.last_name || "");
        setCountryCode(result.country_code || "");
        setPhoneNumber(result.phone || "");
        setCompany(result.company || "");
        setLoading(false);
        setProfileLoading(false);
      }
    }
    fetchData();
    if (user) {
      for (let i = 0; i < user.providerData.length; i++) {
        const providerId = user.providerData[i].providerId;
        if (providerId === "google.com")
          setgoogleProvider(true);
        if (providerId === "password")
          setPasswordProvider(true);
        if (providerId === "microsoft.com")
          setMicrosoftProvider(true);
      }
      setProviderLoading(false);
    }
  }, [user]);

  function LoginInfoSection() {
    if (provider === "google.com" && googleProvider)
      return <GoogleProvider />
    if (provider === "password") {
      if (passwordProvider)
        return <PasswordProvider />
      return <LinkPassword />
    }
    if (provider === "microsoft.com" && microsoftProvider)
      return <MicrosoftProvider />
    return <LinkProvider text={provider} />
  }

  async function handleSaveSetting() {
    try {
      setLoading(true)
      if (firstName.length === 0 || lastName.length === 0)
        throw new Error("First Name or Last name must not be empty!");
      if (phoneNumber.length > 0 || countryCode.length > 0) {
        if (phoneNumber.length === 0)
          throw new Error("Phone number is missing!");
        if (countryCode.length === 0) 
          throw new Error("Country code is missing!");

        const codePattern = /^\+\d{1,4}$/;
        if (!codePattern.test(countryCode))
          throw new Error("Invalid country code!");
        const phonePattern = /^[0-9]+$/;
        if (!phonePattern.test(phoneNumber))
          throw new Error("Invalid phone number!");
      }
      const info = {
        "first_name": firstName,
        "last_name": lastName,
        "country_code": countryCode,
        "phone": phoneNumber,
        "company": company,
      }
      const result = await backend_services
        .fetchProtectedData(`/user/profile`, user!.getIdToken(), undefined, "POST", info)
        .then((res) => {
          setLoading(false);
          return res.json();
        })
      if (result.error)
        return toast.error(result.error);
      return toast.success(result.message);
    }
    catch (e) {
      if (e instanceof Error) {
        toast.error(e.message);
        setLoading(false);
      }
    }
  }

    return (
      <div className="flex flex-col bg-white gap-y-[48px]  w-[534px]">
        <div className="flex flex-col gap-y-2">
          <div className="text-5xl">Account</div>
          <div className="text-2xl text-grey-100">Manage your account information</div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col">
            <p className="text-2xl">Profile</p>
            <hr />
            <div className="flex py-6 justify-between items-center relative">
              {profileLoading && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <Spinner text="Loading" onWhiteBackground />
                </div>
              )}
              <div className="flex items-center">
                <div className={`flex items-center ${profileLoading ? 'invisible' : ''}`}>
                  <ProfilePicture />
                  <div className="flex flex-col ml-4">
                    {user && <p className="font-medium">{user.displayName}</p>}
                    {user && <p className="text-gray-400">{user.email}</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <p className="text-2xl">Login information</p>
            <hr />
            <div className="flex pt-4 gap-x-4">
              <button
                onClick={() => { setProvider("google.com") }}
              >
                <GoogleIcon width={30} height={30} />
              </button>
              <button
                onClick={() => { setProvider("password") }}
              >
                <EmailIcon width={30} height={30} />
              </button>
              <button
                onClick={() => { setProvider("microsoft.com") }}
              >
                <MicrosoftIcon width={30} height={30} />
              </button>
            </div>
            <div className="relative">
              {profileLoading && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <Spinner text="Loading" onWhiteBackground />
                </div>
              )}
              <div className={`${providerLoading ? 'invisible' : ''}`}>
                <LoginInfoSection />
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <p className="text-2xl">General information</p>
            <hr />
            <div className="flex flex-col py-6 gap-y-4 relative">
              {loading && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <Spinner text="Loading" onWhiteBackground />
                </div>
              )}
              <div className={`flex flex-col gap-4 ${profileLoading ? 'invisible' : ''}`}>
                <div className="flex gap-x-6 justify-between">
                  <div className="flex flex-col gap-y-1">
                    <p className="ml-4">First Name</p>
                    <InputBox addUserMessage={setFirstName} isMessageAllowed={true} startText={firstName} />
                  </div>
                  <div className="flex flex-col gap-y-1">
                    <p className="ml-4">Last Name</p>
                    <InputBox addUserMessage={setLastName} isMessageAllowed={true} startText={lastName} />
                  </div>
                </div>
                <div className="flex gap-x-6 justify-between">
                  <div className="flex flex-col  gap-y-1">
                    <div className="flex justify-between">
                      <div className="ml-4">Phone number</div>
                      <div className="text-gray-400">Optional</div>
                    </div>
                    <div className="flex gap-2">
                      <div className="flex w-[80px]">
                        <InputBox addUserMessage={setCountryCode} isMessageAllowed={true} placeholder="+852" startText={countryCode} />
                      </div>
                      <div className="flex w-[152px]">
                        <InputBox addUserMessage={setPhoneNumber} isMessageAllowed={true} placeholder="12345678" startText={phoneNumber} />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-1">
                    <div className="flex justify-between">
                      <div className="ml-4">Company</div>
                      <div className="text-gray-400">Optional</div>
                    </div>
                    <InputBox addUserMessage={setCompany} isMessageAllowed={true} placeholder="Company" startText={company} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid mb-4 lg:flex gap-4 lg:justify-end">
            <button
              className="flex h-12  items-center justify-center gap-1.5 rounded-md px-4 text-base text-purpleHover leading-[19.2px] border border-purpleHover  hover:bg-lightpurple"
              onClick={() => {
                signOut();
                navigator("/login");
              }}
            >
              <div>Log Out from All Sessions</div>
            </button>
            <button
              className="flex h-12 items-center justify-center gap-1.5 rounded-md  bg-primary px-4 text-base leading-[19.2px] text-white hover:bg-purpleHover"
              onClick={handleSaveSetting}
            >
              <RocketIcon />
              <div>Save Setting</div>
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }