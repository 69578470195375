const NewspaperIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path 
        d="M20 3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10H6v-2h12v2zm0-4H6v-2h12v2zm0-4H6V7h12v2z" 
        fill="#03BE42" 
        strokeWidth="1" 
      />
    </svg>
  );

  export {NewspaperIcon};