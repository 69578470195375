import backend_services from "../../services/backend_service";

export const addUserToLoop = async (
  userData: { [key: string]: any },
  tokenPromise: Promise<string>,
) => {
  try {
    const response = await backend_services.fetchProtectedData(
      "/add_user",
      tokenPromise,
      undefined,
      "POST",
      userData,
    );
    return response;
  } catch (error) {
    console.error("Error adding user to loop:", error);
    return null;
  }
};

export function createDataForNewUser(email: string) {
  return {
    email: email,
  };
}
