import { ButtonHTMLAttributes, ReactElement } from "react";
interface ChatActionProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  head: string;
  body: string;
  icon: ReactElement;
  iconBg: string;
}
export default function ChatAction({
  head,
  body,
  icon,
  iconBg,
  ...props
}: ChatActionProps) {
  return (
    <button
      className={`group inline-flex w-full items-center  justify-start gap-4 rounded-md bg-white p-2 outline-none transition-all duration-300 hover:bg-lightpurple focus:bg-lightpurple md:p-4`}
      {...props}
    >
      <div
        className={`flex  w-12 items-center justify-center gap-2.5 rounded-md ${iconBg} p-3`}
      >
        <div className="relative h-6 w-6">{icon}</div>
      </div>
      <div className="flex w-full flex-col items-start justify-start gap-0.5 ">
        <div className="flex w-full items-start text-base font-normal leading-[19.2px]  text-neutral-900 md:text-lg md:leading-snug">
          {head}
        </div>
        <div className="flex w-full items-start justify-start text-start text-sm font-normal text-neutral-400 transition-all duration-300 group-hover:text-black group-focus:text-black md:text-base md:leading-snug">
          {body}
        </div>
      </div>
    </button>
  );
}
