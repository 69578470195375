import { UserChat } from "../../models/data";
import { timeSince } from "../../components/utils";
import { ChatNameBox } from "./ChatNameBox";

export function TopChats({
  userChats,
  showAllChats,
}: {
  userChats: UserChat[];
  showAllChats: boolean;
}) {
  return timeSince(userChats[0].timestamp.toDate())
    ?.toLowerCase()
    .includes("today") ? (
    // there is a chat today
    <div className="flex flex-col gap-4 border-b border-grey-400 pb-4">
      <div className="flex flex-col gap-1">
        <div className="p-2.5 text-base leading-[22.4px] text-grey-300">
          Today
        </div>
        <div className="flex flex-col gap-1">
          <ChatNameBox key={userChats[0].chat_id} chat={userChats[0]} />
        </div>
      </div>
      {userChats?.length > 1 && (
        <div className="flex flex-col gap-1">
          <div className="p-2.5 text-base leading-[22.4px] text-grey-300">
            Latest project
          </div>
          <div className="flex flex-col gap-1">
            {userChats.slice(1, showAllChats ? undefined : 5).map((chat) => (
              <ChatNameBox key={chat.chat_id} chat={chat} />
            ))}
          </div>
        </div>
      )}
    </div>
  ) : (
    // there is no chat today. show 3 latest chats only.

    <div className="flex flex-col gap-1 border-b border-grey-400 pb-4">
      <div className="p-2.5 text-base leading-[22.4px] text-grey-300">
        Latest project
      </div>

      <div className="flex flex-col gap-1">
        {userChats.slice(0, showAllChats ? undefined : 5).map((chat) => (
          <ChatNameBox key={chat.chat_id} chat={chat} />
        ))}
      </div>
    </div>
  );
}
