import * as React from "react";
const FilterIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const myClass = props.className;
  return  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V5.586C20.9999 5.85119 20.8946 6.10551 20.707 6.293L14.293 12.707C14.1055 12.8945 14.0001 13.1488 14 13.414V19.719C14 19.871 13.9653 20.021 13.8987 20.1576C13.832 20.2942 13.735 20.4138 13.6152 20.5073C13.4954 20.6008 13.3558 20.6658 13.2071 20.6973C13.0584 20.7288 12.9044 20.7259 12.757 20.689L10.757 20.189C10.5408 20.1348 10.3488 20.01 10.2117 19.8342C10.0745 19.6585 10 19.4419 10 19.219V13.414C9.99994 13.1488 9.89455 12.8945 9.707 12.707L3.293 6.293C3.10545 6.10551 3.00006 5.85119 3 5.586V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3Z"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      className={myClass}
    />
  </svg>
};
export default FilterIcon;
