import * as React from "react";
const StarsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={23}
    height={25}
    viewBox="0 0 23 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.5 6L8.90014 8.81087C9.37574 12.1519 12.298 14.8199 16.0813 15.3671L17 15.5L16.0813 15.6329C12.298 16.1801 9.37574 18.8481 8.90014 22.1891L8.5 25L8.09986 22.1891C7.62426 18.8481 4.702 16.1801 0.918746 15.6329L0 15.5L0.918746 15.3671C4.702 14.8199 7.62426 12.1519 8.09986 8.81087L8.5 6Z"
      fill={props.fill || "#705CF6"}
    />
    <path
      d="M18 0L18.2354 1.77529C18.5151 3.88541 20.2341 5.57045 22.4596 5.91607L23 6L22.4596 6.08393C20.2341 6.42955 18.5151 8.11459 18.2354 10.2247L18 12L17.7646 10.2247C17.4849 8.11459 15.7659 6.42955 13.5404 6.08393L13 6L13.5404 5.91607C15.7659 5.57045 17.4849 3.88541 17.7646 1.77529L18 0Z"
      fill={props.fill || "#705CF6"}
    />
  </svg>
);
export default StarsIcon;
