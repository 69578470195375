import React from "react";
import "./index.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeLayout from "./components/HomeLayout";
import LoginPage from "./pages/login/page";
import SignupPage from "./pages/signup/page";
import { StateProvider } from "./context/StateContext";
import Profile from "./pages/profile/Profile";
import VerifyUser from "./components/verify/Verify";
import InitialListPage from "./pages/initialList/page";
import PreCuratedListPage from "./pages/preCuratedList/page";
import OnboardingHome from "./components/OnboardingHome";
import ForgotPasswordPage from "./pages/forgotpassword/page";
import ResetPasswordPage from "./pages/resetpassword/page";

function App() {
  return (
    <BrowserRouter>
      <StateProvider>
        <Routes>
          <Route path="/" element={<HomeLayout />}></Route>
          <Route path="/list/:listId" element={<HomeLayout />}></Route>
          <Route path="/chat/:chatId" element={<HomeLayout />}></Route>
          <Route path="/chat/" element={<HomeLayout />}></Route>
          <Route path="/profile/:type" element={<Profile />}></Route>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route path="/signup" element={<SignupPage />}></Route>
          <Route path="/verify" element={<VerifyUser />}></Route>
          <Route
            path="/initial-list-builder"
            element={<InitialListPage />}
          ></Route>
          <Route
            path="/initial-list-builder/:action"
            element={<InitialListPage />}
          ></Route>
          <Route
            path="/pre-curated-lists"
            element={<PreCuratedListPage />}
          ></Route>
          <Route path="/onBoarding" element={<OnboardingHome />}></Route>
          <Route
            path="/forgotPassword"
            element={<ForgotPasswordPage />}
          ></Route>
          <Route path="/resetPassword" element={<ResetPasswordPage />}></Route>
        </Routes>
      </StateProvider>
    </BrowserRouter>
  );
}

export default App;
