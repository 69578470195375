export default function RequestObjective({
  requestData,
}: {
  requestData: { objective: string; requirements: string[] };
}) {
  return (
    <div>
      <div className="mb-2">
        <h4 className="text-lg font-medium">Objective:</h4>
        <p>{requestData?.objective}</p>
      </div>
      <h4 className="text-lg font-medium">Requirements:</h4>
      <ul>
        {requestData?.requirements?.length > 0 &&
          requestData.requirements.map((req: string, index: number) => {
            return <li key={`RequestID${index}`}>{req}</li>;
          })}
      </ul>
    </div>
  );
}
