import React from 'react';

const OfficeBuildingIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="3" y="5" width="18" height="16" fill="#03BE42" stroke="#03BE42" strokeWidth="1" />
    <rect x="7" y="8" width="3" height="2" fill="white" />
    <rect x="10" y="8" width="3" height="2" fill="white" />
    <rect x="13" y="8" width="3" height="2" fill="white" />
    <rect x="7" y="12" width="3" height="2" fill="white" />
    <rect x="10" y="12" width="3" height="2" fill="white" />
    <rect x="13" y="12" width="3" height="2" fill="white" />
    <rect x="8" y="19" width="8" height="3" fill="white" />
  </svg>
);

export {OfficeBuildingIcon};