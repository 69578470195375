import { useState } from "react";
import { useParams } from "react-router-dom";
import useChatUpdates from "src/hooks/useChatUpdates";
import { downloadList, reshapeCompanyData } from "../utils";
import TableView from "./TableView";

export function FinalUpdateTable() {
  const PAGE_SIZE = 100;
  const { chatId: currentOpenChat } = useParams();
  const [page, setPage] = useState(1);

  const chatUpdates = useChatUpdates();
  const plan_update = chatUpdates.find(
    (u) => u.type === "research_plan_complete",
  );

  const finalUpdate = chatUpdates.find(
    (update) => update.type === "final_list_complete",
  );

  if (!finalUpdate || finalUpdate.type !== "final_list_complete") {
    // show empty table
    return <></>;
  }
  const companies = reshapeCompanyData(finalUpdate.data);
  const dataLength = companies.length;
  const totalPages = Math.ceil(dataLength / PAGE_SIZE);
  const hasNextPage = page < totalPages;
  const handleNextPage = () => {
    if (hasNextPage) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const paginatedCompanies = companies.slice(
    (page - 1) * PAGE_SIZE,
    (page - 1) * PAGE_SIZE + PAGE_SIZE,
  );
  return (
    <TableView
      type="final"
      companiesLoading={false}
      companies={paginatedCompanies}
      page={page}
      totalPages={totalPages}
      rowCount={dataLength}
      handleNextPage={handleNextPage}
      handlePreviousPage={handlePreviousPage}
      hasNextPage={hasNextPage}
      pageSize={PAGE_SIZE}
      columnDetails={
        plan_update?.type === "research_plan_complete" ? plan_update?.data : []
      }
      downloadHandler={() => {
        downloadList({
          companyList: companies,
          currentOpenChatId: currentOpenChat,
        });
      }}
    />
  );
}
