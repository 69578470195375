import { useEffect } from "react";
interface Options {
    delay?: boolean;
}
export const useClickOutside = (
    ref: React.RefObject<HTMLElement>,
    callback: (e: Event) => void,
    options?: Options
) => {
    const handleClick = (e: Event) => {
        if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
            callback(e);
        }
    };

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (options?.delay) {
            timeout = setTimeout(() => {
                document.addEventListener("click", handleClick);
            }, 100);
        }
        else { document.addEventListener("click", handleClick); }
        return () => {
            if (timeout) clearTimeout(timeout);
            document.removeEventListener("click", handleClick);
        };
    });
};