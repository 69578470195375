import { useState } from "react";
import KurationSmallIcon from "src/components/svgs/KurationSmall";
import { Column } from "src/hooks/useChatTable";
import { FormSelect } from "./FormSelect";
import { ColumnsTextArea } from "./ColumnsTextArea";
import { MinusIcon } from "@heroicons/react/24/outline";
import { OnlyIconButton } from "src/components/elements/OnlyIconButton";
import { IconButton } from "src/components/elements/IconButton";
import PlusIcon from "src/components/svgs/Plus";
import StarsIcon from "src/components/svgs/Stars";
import { twMerge } from "tailwind-merge";
import { CustomTool } from "src/models/tools";
import { useAuth } from "src/context/AuthContext";
import backend_services from "src/services/backend_service";
import { toast } from "react-toastify";
import { SimpleSpinner } from "src/components/elements/Spinner";

export interface FormatOption {
  id: "text" | "longAnswer" | "boolean" | "website" | "number" | "select";
  name: string;
}
interface CustomFilterFormFields {
  objective: string;
  outcome: string;
  format: null | FormatOption["id"];
  options: NewOption[];
  default: string;
  name: string;
}
export function CustomFilterForm({
  columns,
  onSuccess,
}: {
  columns?: Column[];
  onSuccess: () => void;
}) {
  const options: Array<FormatOption> = [
    { id: "text", name: "Text" },
    { id: "longAnswer", name: "Long answer" },
    { id: "boolean", name: "True/False" },
    { id: "website", name: "Website" },
    { id: "number", name: "Number" },
    { id: "select", name: "Select" },
  ];
  const newOptions: NewOption[] = [
    { id: "new_option_1", text: "New Option" },
    { id: "new_option_2", text: "New Option 2" },
  ];
  const { user } = useAuth();
  const [addFilterLoading, setAddFilterLoading] = useState(false);

  const [errors, setErrors] = useState<Array<keyof CustomFilterFormFields>>([]);
  const [filterForm, setFilterForm] = useState<CustomFilterFormFields>({
    objective: "",
    outcome: "",
    format: null,
    options: newOptions,
    default: "",
    name: "",
  });

  async function submit() {
    if (Object.values(filterForm).some((value) => !value)) {
      const newErrors: Array<keyof CustomFilterFormFields> = [];
      Object.entries(filterForm).forEach((e) => {
        if (!e[1]) {
          newErrors.push(e[0] as keyof CustomFilterFormFields);
        }
      });
      // console.log(newErrors);
      setErrors(Array.from(new Set(newErrors)));
      return;
    }
    if (!filterForm.format || !user) {
      return;
    }
    const customTool: CustomTool["fields"] = {
      default: filterForm.default,
      format:
        filterForm.format !== "select"
          ? filterForm.format
          : filterForm.options.map((o) => o.text),
      name: filterForm.name,
      objective: filterForm.objective,
      outcome: filterForm.outcome,
    };
    setAddFilterLoading(true);
    await backend_services
      .fetchProtectedData(
        `/custom_tool`,
        user?.getIdToken(),
        undefined,
        "POST",
        customTool,
      )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("invalid result");
        }
        const data = res.json();
        // console.log(data);
        return data;
      })
      .catch((err) => {
        toast.error("Failed to create filter. Please try again.");
      })
      .finally(() => {
        setAddFilterLoading(false);
        toast.success("New filter created in your filter library.");
        onSuccess();
      });
  }

  // console.log(filterForm);
  if (addFilterLoading) {
    return (
      <div className="flex h-full items-center justify-center gap-2 py-5">
        <SimpleSpinner radius={20} overrideClasses="border-black" />
        loading..
      </div>
    );
  }
  return (
    <div className="flex w-full flex-1 items-end">
      <div className="w-full">
        <div className="w-full border-t  border-neutral-300 px-6 py-9">
          <div className="inline-flex h-[67px]  flex-col items-start justify-start gap-5">
            <div className="inline-flex h-9  items-center justify-start gap-2">
              <div className="relative h-7 w-7">
                <KurationSmallIcon />
              </div>
              <div className="text-lg font-normal leading-[25.20px] text-neutral-900">
                Kuration AI
              </div>
            </div>
            <div className="text-base font-normal text-neutral-600">
              I'm here to help you create a filter 👋
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2.5 px-6 pb-9">
          <div className="flex flex-col gap-1">
            <label htmlFor="objective" className="pl-1">
              What answer you want this custom data point to show? what data do you need?
            </label>
            <ColumnsTextArea
              overrideClasses={`${errors.includes("objective") ? "border border-red-600 hover:border-red-800" : ""} `}
              placeholder="e.g. Determine remote work policy of a company using its /website e.g"
              val={filterForm.objective}
              setVal={(v) => {
                if (errors.includes("objective")) {
                  setErrors((errs) => [
                    ...errors.filter((e) => e !== "objective"),
                  ]);
                }
                setFilterForm((form) => ({ ...form, objective: v }));
              }}
              columns={columns || []}
              id="objective"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="outcome" className="pl-1">
            How do you want the answer to look
            </label>
            <ColumnsTextArea
              overrideClasses={`${errors.includes("outcome") ? "border border-red-600 hover:border-red-800" : ""} `}
              placeholder="e.g. i want simple answer in form of remote or not remote. e.g. I want a direct link to the page"
              val={filterForm.outcome}
              setVal={(v) => {
                if (errors.includes("outcome")) {
                  setErrors((errs) => [
                    ...errors.filter((e) => e !== "outcome"),
                  ]);
                }
                setFilterForm((form) => ({ ...form, outcome: v }));
              }}
              columns={columns || []}
              id="outcome"
            />
          </div>
          <div className="mb-1 flex flex-col gap-1">
            <label htmlFor="format" className="pl-1">
              Please choose output format.
            </label>
            <FormSelect
              overrideClasses={`${errors.includes("format") ? "border border-red-600 hover:border-red-800" : ""} `}
              id="format"
              options={options}
              val={filterForm.format || ""}
              setVal={(v) => {
                if (errors.includes("format")) {
                  setErrors((errs) => [
                    ...errors.filter((e) => e !== "format"),
                  ]);
                }
                setFilterForm((form) => ({
                  ...form,
                  format: v as FormatOption["id"],
                }));
              }}
            />
          </div>
          {filterForm.format === "select" && (
            <div className="ml-6 mt-3">
              <OptionAdder
                options={filterForm.options}
                setOptions={(options) => {
                  if (errors.includes("options")) {
                    setErrors((errs) => [
                      ...errors.filter((e) => e !== "options"),
                    ]);
                  }
                  setFilterForm((form) => ({ ...form, options: options }));
                }}
              />{" "}
            </div>
          )}
          <div className="flex flex-col gap-1">
            <label htmlFor="default" className="pl-1">
              Default answer:
            </label>
            <input
              id="default"
              value={filterForm.default}
              className={twMerge(
                "h-10 flex-1 rounded-md border border-neutral-200 px-2.5 py-3 text-sm leading-none outline-none    focus-within:border-purple hover:border-purple",
                `${errors.includes("default") ? "border border-red-600 hover:border-red-800" : ""} `,
              )}
              placeholder="e.g. Not found or x"
              onChange={(e) => {
                if (errors.includes("default")) {
                  setErrors((errs) => [
                    ...errors.filter((e) => e !== "default"),
                  ]);
                }
                setFilterForm((form) => ({ ...form, default: e.target.value }));
              }}
            />
          </div>
          <div className="mt-10 flex flex-col gap-1">
            <label htmlFor="name" className="pl-1">
              Give filter a name.
            </label>
            <div className="group relative ">
              <input
                id="name"
                placeholder="Name of filter, e.g. Remote policy extractor"
                onChange={(e) => {
                  if (errors.includes("name")) {
                    setErrors((errs) => [
                      ...errors.filter((e) => e !== "name"),
                    ]);
                  }
                  setFilterForm((form) => ({ ...form, name: e.target.value }));
                }}
                className={twMerge(
                  "peer  inline-flex max-h-14 w-full resize-none items-center  justify-center gap-2.5 rounded-md border-2 border-neutral-400 bg-white py-3.5 pl-3  pr-28 shadow-[2px_2px_20px_0px_#00000014]  outline-none transition-all duration-300  focus-within:border-purple hover:border-purple   md:pl-4",
                  `${errors.includes("name") ? "border border-red-600 hover:border-red-800" : ""} `,
                )}
              />
              <button
                onClick={(e) => {
                  submit();
                }}
                className={`top-bottom-3 absolute right-2.5 top-3 flex h-8 items-center justify-center  gap-2.5 rounded bg-neutral-300 p-[7px] text-white outline-none transition-all  duration-300   focus:bg-purpleHover ${
                  filterForm.name.length > 0
                    ? "bg-purple hover:bg-purpleHover"
                    : " peer-focus:bg-lightpurple "
                }   `}
              >
                <span>Create</span>{" "}
                <StarsIcon height="17.14px" fill="white" width="17.14px" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface NewOption {
  id: string;
  text: string;
}

const OptionAdder = ({
  options,
  setOptions,
}: {
  options: NewOption[];
  setOptions: (options: NewOption[]) => void;
}) => {
  return (
    <div className="flex flex-col gap-2">
      <div>
        <div className="mb-2 text-neutral-600">
          choose the allowed options.
        </div>
        {options.map((o) => {
          return (
            <div key={o.id} className="flex gap-2">
              <input
                className="h-10 flex-1 rounded-md border border-neutral-400 px-2.5 py-3.5  outline-none  hover:border-indigo-400 focus:outline-1"
                value={o.text}
                onChange={(e) => {
                  const optionCopy = [...options];
                  const opt = optionCopy.find((opt) => opt.id === o.id);
                  if (opt) {
                    opt.text = e.target.value;
                  }
                  setOptions(optionCopy);
                }}
              />
              <OnlyIconButton
                onClick={() => {
                  setOptions([...options.filter((opt) => opt.id !== o.id)]);
                }}
                icon={<MinusIcon width={20} />}
              />
            </div>
          );
        })}
      </div>
      <IconButton
        icon={<PlusIcon className="fill-purpleHover" />}
        text={"Add option"}
        variant="outline"
        onClick={() => {
          setOptions([
            ...options,
            {
              id: `new_option_${new Date().getTime()}`,
              text: `New option ${options.length + 1}`,
            },
          ]);
        }}
      />
    </div>
  );
};
