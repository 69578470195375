import React, {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getColumnDisplayName,
  getCompanyNameColumn,
  getDomainNameColumn,
  getUniqueColumns,
  reorderColumns,
  sentenceCase,
} from "../utils";
import { Company, Field, ResearchPlanItem } from "../../models/data";
import { twMerge } from "tailwind-merge";
import QuestionMarkIcon from "src/components/svgs/QuestionMark";
import FullScreenIcon from "src/components/svgs/FullScreen";
import {
  Column,
  CustomToolColumn,
  KurationToolColumn,
} from "src/hooks/useChatTable";
import { toast } from "react-toastify";
import backend_services from "src/services/backend_service";
import { useAuth } from "src/context/AuthContext";
import {
  AltColumn,
  getColumnIdsFromColumnList,
  ToolColumnHeader,
  getOriginalOrAltItem,
  CellText,
  SourceTooltip,
  SkippedCell,
  LoadingCell,
} from "./tableUtils";
import { useShowCostQuery } from "src/hooks/useSearchQuery";
import { useStateContext } from "src/context/StateContext";
import { IconButton } from "../elements/IconButton";
import PlusIcon from "../svgs/Plus";
export const CompanyListTable = memo(
  ({
    companies,
    height = "full",
    compact = false,
    startIndex,
    columnDetails = [],
    setAddColumnModalOpen,
    setAddRowsModalOpen,
    companyLoadingColumns,
    alternativeColumns = [],
    columnList,
    rowIds,
  }: {
    companies: Company[];
    height?: "small" | "full";
    /** whether or not to show row numbers */
    compact?: boolean;
    /** start index to show for table */
    startIndex: number;
    columnDetails?: Array<ResearchPlanItem>;
    setAddColumnModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    setAddRowsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    /** when companies have a cell loading, the row.columnIdLoading = true */
    companyLoadingColumns?: string[][];
    /** A way to making website and website_url like columns same */
    alternativeColumns?: AltColumn[];
    /** List of columns from firebase (not applicable to chats that don't have columns, e.g. initial list) */
    columnList?: Array<Column>;
    /** firebase id of a row */
    rowIds?: string[];
  }) => {
    const [isFullscreen, setIsFullscreen] = useState(false);

    const { user } = useAuth();
    const { chatId } = useParams();
    const navigate = useNavigate();

    const columns =
      (columnList && getColumnIdsFromColumnList(columnList)) ||
      getUniqueColumns(companies, {
        ignoreCustomColumns: true,
      });

    const visibleToolColumns = columnList?.filter(
      (c) =>
        (c.type === "tool_column" || c.type === "custom_tool_column") &&
        !c.hidden,
    ) as (KurationToolColumn | CustomToolColumn)[];
    const userAddedColumnIds = visibleToolColumns?.map((c) => c.id);
    const localColumns = reorderColumns(
      columns
        .filter((l) => !userAddedColumnIds?.includes(l))
        .filter(
          (c) =>
            // we need to only keep the alternative column name and ignore its original if and only if we have both
            !alternativeColumns.find((a) => {
              return a.original === c && columns.includes(a.alternative);
            }),
        ),
    );
    if (height === "full") {
      console.log("RR");
    }
    const localData = [...companies];

    localData.forEach((company) =>
      company.forEach((field) => {
        field.data_field = field.data_field.toLowerCase();
      }),
    );
    const companyNameColumn = getCompanyNameColumn(localColumns);
    const domainColumn = getDomainNameColumn(localColumns);
    // console.log(column_list, localColumns);
    async function colRunner(
      col: KurationToolColumn | CustomToolColumn,
      count: number,
      pause: boolean,
    ) {
      if (!user) {
        return;
      }
      await backend_services
        .fetchProtectedData(
          `/chat/${chatId}/column/${col.id}/${pause === false ? "run" : "pause"}?limit=${count}`,
          user?.getIdToken(),
          undefined,
          "POST",
        )
        .then((res) => {
          if (res.status === 402) {
            toast.warn(
              "You ran out of credits. Please subscribe to a plan to continue.",
            );
            setTimeout(() => {
              navigate("/profile");
            }, 3000);
            return;
          }
          if (res.status !== 200) {
            throw new Error("invalid result");
          }
          return res.json();
        })
        .catch((err) => {
          if (pause) {
            toast.error("Failed to pause column. Please try again.");
          } else {
            toast.error("Failed to run column. Please try again.");
          }
        });
      if (false) {
        // console.log(column_add_result);
      }
    }
    const escFunction = useCallback(
      (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          if (isFullscreen) {
            setIsFullscreen(false);
          }
        }
      },
      [isFullscreen],
    );

    useEffect(() => {
      document.addEventListener("keydown", escFunction, false);
      return () => {
        document.removeEventListener("keydown", escFunction, false);
      };
    }, [escFunction]);
    const finalColumns = localColumns;
    async function updateCell({
      rowId,
      colId,
      newValue,
      onSuccess,
    }: {
      rowId: string;
      colId: string;
      newValue: string;
      onSuccess: () => void;
    }) {
      if (!user) {
        return;
      }
      const cell_update_result = await backend_services
        .fetchProtectedData(
          `/chat/${chatId}/table/update`,
          user?.getIdToken(),
          undefined,
          "POST",
          {
            row_id: rowId,
            col_id: colId,
            new_value: newValue,
          },
        )
        .then((res) => {
          if (res.status !== 200) {
            throw new Error("invalid result");
          }
          onSuccess();
          return res.json();
        })
        .catch((err) => {
          if (err) {
            toast.error("Failed to update cell. Please try again.");
          }
        });
      if (process.env.NODE_ENV === "development") {
        console.log(cell_update_result);
      }
    }

    if (!setAddRowsModalOpen && (!finalColumns || finalColumns.length < 1)) {
      return <div>Main table updated with latest data.</div>;
    }
    if (setAddRowsModalOpen && (!finalColumns || finalColumns.length < 1)) {
      return (
        <div className="flex w-full flex-col-reverse items-center justify-center gap-2 p-3 ">
          <div className="text-center text-gray-600">
            <div>No companies yet.</div>
            <div>
              <div>
                Kuration is all about curating companies, add some to begin.
              </div>
              <div>You can add as many as you want.</div>
            </div>
          </div>
          <IconButton
            overrideClasses="max-w-max"
            icon={<PlusIcon className="fill-purpleHover" />}
            text={"Add new companies"}
            variant="outline"
            onClick={() => {
              setAddRowsModalOpen(true);
            }}
          />
        </div>
      );
    }

    return (
      <div
        className={twMerge(
          `w-full rounded `,
          `${height === "small" ? "max-h-[29vh] overflow-auto" : ""}`,
          `${isFullscreen ? "max-w-screen fixed inset-0 z-50 max-h-screen overflow-auto bg-white " : ""}`,
        )}
      >
        <table
          className={twMerge(
            `relative  max-w-full overflow-auto text-left text-sm font-light`,
          )}
        >
          <thead className="z-10 font-medium">
            <tr className=" divide-x border-r border-grey-400">
              {!compact && (
                <th
                  className="group sticky  top-0 w-11 border-b border-t"
                  onClick={() => {
                    setIsFullscreen(!isFullscreen);
                  }}
                >
                  <div className=" cursor-pointer  bg-bg-2 px-7 py-4">
                    <FullScreenIcon className="fill-grey-100   group-hover:fill-black" />
                  </div>
                </th>
              )}
              {finalColumns.map((column, index) => {
                const columnDetail = columnDetails.find(
                  (d) => d.data_field.toLowerCase() === column,
                );
                const originalCol = columnList?.find(c => c.id.toLowerCase() === column);
                

                return (
                  <th
                    className={twMerge(
                      "sticky -top-1 z-20 border-b border-l border-t p-0 transition-all duration-300 hover:bg-lightpurple",
                      `${index === 0 ? "left-0 z-30 " : ""}`,
                    )}
                    key={index}
                    title={`${columnDetail?.Question || ""}\n\n${columnDetail?.Answer_Structure || ""}`}
                  >
                    <div className="flex h-full max-w-[20ch] items-center gap-1  truncate bg-bg-2 px-5 py-3  text-lg font-normal text-grey-100 transition-all duration-300 hover:bg-lightpurple md:max-w-[24cqw]">
                      <span className="truncate"> {originalCol ? getColumnDisplayName(originalCol) : sentenceCase(column, "_")} </span>
                      {columnDetail ? (
                        <span className="inline-block cursor-help">
                          <QuestionMarkIcon className="h-4 w-4 fill-grey-100" />
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </th>
                );
              })}
              {visibleToolColumns?.map((col) => (
                <th
                  key={col.timestamp.toString()}
                  className="sticky -top-1 z-20 border-b border-l border-t p-0 transition-all duration-300 hover:bg-lightpurple"
                >
                  <ToolColumnHeader col={col} colRunner={colRunner} />
                </th>
              ))}
              {setAddColumnModalOpen && (
                <th
                  onClick={() => {
                    setAddColumnModalOpen(true);
                    // setFiltersOpen(true);
                  }}
                  className="group sticky top-0 border-b border-t"
                >
                  <div className="relative z-50 flex max-w-[20ch] cursor-pointer items-center gap-1 truncate  bg-bg-2 px-5 py-3 text-lg font-normal text-grey-100 md:max-w-[24cqw]">
                    + Add Column
                  </div>
                </th>
              )}
            </tr>
          </thead>
          <tbody className="divide-y  border-r border-grey-400">
            {localData.map((row, rowIndex) => (
              <tr className="divide-x   border-grey-400" key={rowIndex}>
                {!compact && (
                  <td
                    className={twMerge(
                      "flex items-center justify-center  px-7 py-4",
                      `${height === "small" ? "bg-bg-2" : ""}`,
                    )}
                  >
                    {rowIndex + startIndex}
                  </td>
                )}
                {finalColumns.map((column, columnIndex) => {
                  const cell = getOriginalOrAltItem(
                    row,
                    alternativeColumns,
                    column,
                  );
                  const columnType =
                    column === companyNameColumn ? "name" : null;

                  let domainName: string | null = null;
                  // only set domain Name on the cell which will display company name
                  if (columnType === "name" && domainColumn) {
                    domainName =
                      getOriginalOrAltItem(
                        row,
                        alternativeColumns,
                        domainColumn,
                      )?.value || null;
                  }
                  const cellKey = `${rowIndex}-${columnIndex}`;

                  return (
                    <RowCell
                      key={cellKey}
                      cell={cell}
                      domainName={domainName || ""}
                      column={column}
                      columnIndex={columnIndex}
                      rowIds={rowIds || []}
                      rowIndex={rowIndex}
                      isCellLoading={false}
                      updateCell={updateCell}
                      type="normal"
                    />
                  );
                })}
                {visibleToolColumns?.map((column, columnIndex) => {
                  const cell = row
                    .filter(
                      (f) =>
                        f.data_field.toLowerCase() ===
                        column.data_field.toLowerCase(),
                    )
                    .at(-1);
                  const isCellLoading =
                    companyLoadingColumns &&
                    companyLoadingColumns[rowIndex]?.includes(column.id);
                  if (isCellLoading) {
                    // console.log({ isCellLoading, companyLoadingColumns });
                  }

                  const cellKey = `${rowIndex}-${columnIndex}`;

                  return (
                    <RowCell
                      key={cellKey}
                      cell={cell}
                      column={column}
                      columnIndex={columnIndex}
                      domainName={""}
                      isCellLoading={isCellLoading === true}
                      rowIds={rowIds || []}
                      rowIndex={rowIndex}
                      updateCell={updateCell}
                      type="col"
                    />
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="my-4 flex flex-row items-center justify-start gap-2 pl-2 text-xs">
          <p>
            <a target="_blank" href="https://clearbit.com" rel="noreferrer">
              Logos provided by Clearbit
            </a>
          </p>
        </div>
      </div>
    );
  },
);

function RowCell({
  type,
  rowIds,
  cell,
  column,
  rowIndex,
  columnIndex,
  domainName,
  isCellLoading,
  updateCell,
}: {
  type: "col" | "normal";
  cell?: Field;
  column: Column | string;
  rowIds: string[];
  rowIndex: number;
  columnIndex: number;
  domainName: string;
  isCellLoading: boolean;
  updateCell({
    rowId,
    colId,
    newValue,
    onSuccess,
  }: {
    rowId: string;
    colId: string;
    newValue: string;
    onSuccess: () => void;
  }): Promise<void>;
}) {
  const { setFiltersOpen, setChatOpen, setDpeState } = useStateContext();
  const showCost = useShowCostQuery();
  if (isCellLoading) {
    console.log("");
  }
  const cellValue = cell?.value;
  const cellSource = cell?.source;
  const status = cell?.type === "custom_column" && cell?.status;
  const cellKey = `${rowIndex}-${columnIndex}`;

  const [waitingForSave, setWaitingForSave] = useState(false);
  const [editableValue, setEditableValue] = useState(cellValue);
  const [isEditing, setEditing] = useState(false);

  const handleDoubleClick = () => {
    setEditing(true);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEditableValue(event.target.value);
  };
  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      event.currentTarget.blur();
    }
  }

  const handleBlur = () => {
    setEditing(false);
    // updateCell(rowId, columnId, value);
    // console.log("NEW VALUE:", editableValue);
    if (!editableValue) {
      return;
    }
    if (editableValue === cellValue) {
      return;
    }
    updateCell({
      rowId: rowIds[rowIndex],
      colId: typeof column === "string" ? column : column.data_field,
      newValue:
        typeof editableValue === "string"
          ? editableValue
          : JSON.stringify(editableValue),
      onSuccess: () => {
        setTimeout(() => {
          setWaitingForSave(false);
        }, 1000);
      },
    });
    setWaitingForSave(true);
  };

  function handleCellClick(
    cell: Field | undefined,
    column: Column | string,
    rowIndex: number,
  ) {
    const rowId = rowIds?.[rowIndex];
    if (process.env.NODE_ENV !== "production") {
      console.log({ rowId, cell });
    }
    if (cell?.run_logs && cell.run_logs.length > 0) {
      setFiltersOpen(false);
      setChatOpen(true);
      setDpeState({
        isOpen: true,
        run_logs: cell.run_logs,
        cell: cell,
        type: "langsmith_dpe",
        column: column,
      });
    } else if (
      cell &&
      cell.type === "custom_column" &&
      typeof column !== "string" &&
      (column?.type === "tool_column" || column?.type === "custom_tool_column")
    ) {
      setFiltersOpen(false);
      setChatOpen(true);
      setDpeState({
        isOpen: true,
        cell: cell,
        column: column,
        type: "kuration_tool_dpe",
      });
    } else {
      setDpeState({
        isOpen: false,
        type: "null_dpe",
      });
    }
  }
  // if (type === "normal") {
  //   return (
  //     <td
  //       onClick={() => {
  //         handleCellClick(cell, column, rowIndex);
  //       }}
  //       key={cellKey}
  //       className={twMerge(
  //         "group relative h-12 cursor-pointer bg-white transition-all duration-300 hover:bg-lightpurple",
  //         `${columnIndex === 0 ? "sticky left-0 z-20" : ""}`,
  //         `${showCost && cell?.type === "final_researched" && cell.free === false ? "bg-orange-200" : ""}`,
  //       )}
  //     >
  //       <div className="max-w-[20ch] truncate  md:max-w-[24cqw]">
  //         <TableCell content={cellValue ?? ""} domainName={domainName} />
  //       </div>
  //       {cellSource && (
  //         /** the source tooltip is active on group hover */
  //         <SourceTooltip content={cellSource} />
  //       )}
  //     </td>
  //   );
  // }
  return (
    <td
      onDoubleClick={handleDoubleClick}
      onClick={() => {
        handleCellClick(cell, column, rowIndex);
      }}
      key={cellKey}
      className={twMerge(
        "group relative h-12 cursor-pointer bg-white transition-all duration-300 hover:bg-lightpurple",
        `${type === "normal" && columnIndex === 0 ? "sticky left-0 z-20" : ""}`,
        `${showCost && cell?.type === "custom_column" && cell.free === false ? "bg-orange-200" : ""}`,
      )}
    >
      {isCellLoading || waitingForSave ? (
        <LoadingCell text={waitingForSave ? "Saving" : "Running"} />
      ) : status === "skipped" ? (
        <SkippedCell />
      ) : isEditing ? (
        <input
          className="h-full w-full px-3 py-4  outline-none"
          type="text"
          value={
            typeof editableValue === "string"
              ? editableValue
              : JSON.stringify(editableValue)
          }
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          autoFocus
        />
      ) : (
        <div>
          <div className="max-w-[20ch] truncate  md:max-w-[24cqw]">
            <CellText content={cellValue ?? ""} domainName={domainName} />
          </div>
          {(cellSource || (cell?.run_logs?.length || 0) > 0) && (
            /** the source tooltip is active on peer hover */
            <SourceTooltip content={cellSource || ""} />
          )}
        </div>
      )}
    </td>
  );
}
