import { ReactElement, useState } from "react";
import { IconBox } from "../../components/elements/IconBox";
import {
  ArrowPathIcon,
  ChevronDownIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import backend_services from "../../services/backend_service";
import { useAuth } from "../../context/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { twMerge } from "tailwind-merge";
import { SimpleSpinner } from "../../components/elements/Spinner";
import { Fields } from "../../models/company";
import { FloatingLabelInput } from "../../components/elements/FloatingLabelInput";
import { numberFormatter } from "../../components/utils";

interface ParentProps {
  icon: ReactElement;
  name: string;
  field: keyof Fields;
  selectedFields: string[];
  toggleFieldSelect: (field: keyof Fields, value: string) => void;
  unselectAll: (field: keyof Fields) => void;
}
export const FilterRow = ({
  name,
  icon,
  field,
  selectedFields,
  toggleFieldSelect,
  unselectAll,
}: ParentProps) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div>
      <div
        onClick={() => {
          setCollapsed(!collapsed);
        }}
        className="flex w-full cursor-pointer items-center  justify-between rounded-md  p-4 pb-1 transition-all duration-300 "
      >
        <div className="flex items-center gap-3">
          {icon}
          <div className="flex flex-grow items-center justify-between gap-4 ">
            <div className="text-lg ">{name}</div>
            {selectedFields.length > 0 && (
              <div className="group flex items-center gap-2 text-sm  leading-7 text-grey-100">
                <div className="group-hover:hidden">
                  {selectedFields.length} selected
                </div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    unselectAll(field);
                  }}
                  className="hidden items-center justify-center gap-1 rounded-lg bg-purple px-3   text-white group-hover:flex"
                >
                  <div>Unselect</div>
                  <TrashIcon width={20} />
                </button>
              </div>
            )}
          </div>
        </div>
        <button>

        
        <IconBox
          overrideClasses="group  h-9 w-9 border-none bg-grey-600 transition-all duration-300 hover:bg-purple"
          icon={
            <ChevronDownIcon
              width={18}
              className={twMerge(
                "text-black transition-all duration-300 group-hover:text-white",
                `${collapsed ? "" : "rotate-180"}`,
              )}
            />
          }
        />
        </button>
      </div>
      <div
        className={twMerge(
          "flex h-0 transition-all duration-300",
          `${collapsed ? "" : "flex h-44 overflow-auto rounded-md  "}`,
        )}
      >
        <div
          className={twMerge(
            "flex-grow rounded-md px-4  ",
            `${collapsed ? "hidden" : "block"}`,
          )}
        >
          <FieldSelector
            field={field}
            toggleFieldSelect={toggleFieldSelect}
            selectedFields={selectedFields}
          />
        </div>
      </div>
    </div>
  );
};

export const FieldSelector = ({
  field,
  toggleFieldSelect,
  selectedFields,
}: {
  field: keyof Fields;
  toggleFieldSelect: (field: keyof Fields, value: string) => void;
  selectedFields: string[];
}) => {
  const { user } = useAuth();
  const [searchQuery, setSearchQuery] = useState("");
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["unique_data", field],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return (
        user &&
        backend_services
          .fetchProtectedData(`/unique_data?field=${field}`, user?.getIdToken())
          .then((res) => res.json())
      );
    },
  });

  const fieldsArray = data as Array<{ count: number; value: string }>;
  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <SimpleSpinner radius={20} overrideClasses="border-black" />
      </div>
    );
  } else if (error) {
    return (
      <div className="flex h-full items-center justify-center ">
        <button
          className="flex items-center gap-2"
          onClick={() => {
            refetch();
          }}
        >
          <ArrowPathIcon width={20} className="text-black" />
          <div className="text-black">Retry</div>
        </button>
      </div>
    );
  }
  const query = searchQuery.toLowerCase();
  return (
    <div className="relative ">
      <div className="sticky pt-2 top-0 bg-white pb-2">
        <FloatingLabelInput
          idFor={field}
          labelText={`Search ${field}`}
          overrideClasses="border-grey-100 bg-white focus:border-grey-100"
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
        />
      </div>
      <div className="flex flex-wrap gap-2">
        {fieldsArray
          .filter((f) => f.value?.includes(query))
          .map((f) => {
            const isSelected = selectedFields.includes(f.value);
            return (
              <button
                key={f.value}
                className={twMerge(
                  "flex gap-1 rounded-xl border border-transparent bg-grey-600 px-3 py-1 hover:border-purple",
                  `${isSelected ? "bg-purple hover:border-transparent" : ""}`,
                )}
                onClick={() => {
                  toggleFieldSelect(field, f.value);
                }}
              >
                <div
                  className={twMerge(
                    "max-w-[16ch] truncate text-black",
                    `${isSelected ? "text-white" : ""}`,
                  )}
                >
                  {f.value}
                </div>
                <div
                  className={twMerge(
                    "text-grey-200",
                    `${isSelected ? "text-grey-500" : ""}`,
                  )}
                >
                  {numberFormatter.format(f.count)}
                </div>
              </button>
            );
          })}
      </div>
    </div>
  );
};
