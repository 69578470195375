import * as React from "react";
const SendIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.071"
      d="M3.727 8.758l-.432-3.884a1.428 1.428 0 012.032-1.448l8.531 4.041c1.09.516 1.09 2.066 0 2.582L5.327 14.09a1.429 1.429 0 01-2.032-1.448l.432-3.884zm0 0h5"
    ></path>
  </svg>
);
export default SendIcon;
