import * as React from "react";
const PaymentIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg 
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      d="M16.2267 3.5H7.77333C6.74311 3.5 6.22844 3.5 5.81244 3.6467C5.42025 3.7875 5.06539 4.01794 4.77541 4.32012C4.48544 4.6223 4.26816 4.98808 4.14044 5.38906C4 5.82285 4 6.35924 4 7.43291V20.0362C4 20.8084 4.87556 21.2188 5.42933 20.7058C5.5847 20.5605 5.78846 20.4798 6 20.4798C6.21154 20.4798 6.4153 20.5605 6.57067 20.7058L7 21.1036C7.27218 21.3585 7.62928 21.5 8 21.5C8.37072 21.5 8.72782 21.3585 9 21.1036C9.27218 20.8488 9.62928 20.7072 10 20.7072C10.3707 20.7072 10.7278 20.8488 11 21.1036C11.2722 21.3585 11.6293 21.5 12 21.5C12.3707 21.5 12.7278 21.3585 13 21.1036C13.2722 20.8488 13.6293 20.7072 14 20.7072C14.3707 20.7072 14.7278 20.8488 15 21.1036C15.2722 21.3585 15.6293 21.5 16 21.5C16.3707 21.5 16.7278 21.3585 17 21.1036L17.4293 20.7058C17.5847 20.5605 17.7885 20.4798 18 20.4798C18.2115 20.4798 18.4153 20.5605 18.5707 20.7058C19.1244 21.2188 20 20.8084 20 20.0362V7.43291C20 6.35924 20 5.82195 19.8596 5.38996C19.732 4.98878 19.5148 4.62279 19.2248 4.32044C18.9348 4.01809 18.5799 3.78753 18.1876 3.6467C17.7716 3.5 17.2569 3.5 16.2267 3.5Z" 
      stroke={props.stroke}
      stroke-width="1.34997" 
    />
    <path 
      d="M10 10.9L11.1432 12.5L14 8.5" 
      stroke={props.stroke}
      stroke-width="1.34997" 
      stroke-linecap="round" 
      stroke-linejoin="round" 
    />
    <path 
      d="M8 15.5H16" 
      stroke={props.stroke} 
      stroke-width="1.34997" 
      stroke-linecap="round" 
    />
  </svg>
);
export default PaymentIcon;
