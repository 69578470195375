import { ChangeEventHandler } from "react";
import { twMerge } from "tailwind-merge";

export default function Input({
  handleChange,
  value,
  labelText,
  labelFor,
  id,
  name,
  type,
  isRequired = false,
  placeholder,
  customClass,
}: {
  handleChange: ChangeEventHandler<HTMLInputElement>;
  value: string;
  labelText: string;
  labelFor: string;
  id: string;
  name: string;
  type: string;
  isRequired: boolean;
  placeholder: string;
  customClass: string;
}) {
  return (
    <div className="flex flex-col mb-3">
      <label htmlFor={labelFor} className="px-12 text-base">
        {labelText}
      </label>
      <div className="flex justify-center">
        <input
          onChange={handleChange}
          value={value}
          id={id}
          name={name}
          type={type}
          required={isRequired}
          className={twMerge(
            "carr relative mt-2 block w-5/6 appearance-none rounded-md border border-grey-400 px-3 py-3 text-grey-100 placeholder-grey-200 focus:z-10 focus:border-primaryhover focus:outline-none focus:ring-primaryhover sm:text-sm md:px-4 md:py-4",
            customClass,
          )}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}
