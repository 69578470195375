import { Link, useSearchParams } from "react-router-dom";
import { LoginAside } from "../login/LoginAside";
import Spinner from "src/components/elements/Spinner";
import KurationLogo from "src/components/svgs/KurationLogo";
import InputBox from "../profile/InputBox";
import { toast, ToastContainer } from "react-toastify";
import { FormEvent, useEffect, useState } from "react";
import { getAuth, verifyPasswordResetCode } from "firebase/auth";
import firebase_app from "src/firebase/config";
import { RocketIcon } from "src/components/svgs/RocketIcon";
import backend_services from "src/services/backend_service";
import { useAuth } from "src/context/AuthContext";

export default function ResetPasswordPage() {
  const auth = getAuth(firebase_app);
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get("oobCode"); // Extract oobCode from URL

  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("")

  useEffect(() => {
    if (oobCode) {
      verifyPasswordResetCode(auth, oobCode)
        .then((email) => {
          setEmail(email);
        })
        .catch((error) => {
          toast.error("Invalid or expired password reset code.");
        });
    }
  }, [oobCode, auth]);

  async function handleChangePassword(e: FormEvent) {
    e.preventDefault()
    setLoading(true)
    if (newPassword.length < 6 || confirmPassword.length < 6)
      return toast.error("Invalid Password. Password should be at least 6 characters");
    if (newPassword !== confirmPassword) {
      return toast.error("Passwords don't match. Please ensure that the password and confirm password are the same.");
    }
    const result = await backend_services
      .fetchProtectedData(
        `/user/resetPassword`,
        user?.getIdToken(),
        undefined,
        "POST",
        {
          email: email,
          new_password: newPassword,
          confirm_password: confirmPassword,
        }
      )
      .then((res) => {
        setLoading(false);
        return res.json();
      });
    if (result.error)
      return toast.error(result.error);
    return toast.success(result.message);
  }

  return (
    <>
      <div className="flex h-full min-h-screen w-full bg-white">
        <div className="h-full  flex-grow space-y-6  px-4 pt-4 sm:px-6 lg:px-20">
          {loading && (
            <Spinner
              text="Resetting."
              styleClasses={["backdrop-blur-sm z-20"]}
              onWhiteBackground
            />
          )}
          <div className="mb-4 md:mb-6">
            <div className="mt-4 flex justify-center">
              <KurationLogo className="max-w-[200px] lg:max-w-[300px]" />
            </div>
            <h2 className="mt-3 text-center text-2xl font-normal leading-[35.2px] text-black lg:text-4xl lg:leading-[55.2px]">
              Meet your new Business <br></br>Research AI Agent
            </h2>
            <p className="text-center text-base font-normal  text-grey-100 md:text-2xl md:leading-[33.6px]">
              Reset your password
            </p>
          </div>
          <div className="flex flex-col items-center gap-y-4">
            <div>
              <div>New password</div>
              <div className="w-[387px]">
                {<InputBox type={"password"} addUserMessage={setNewPassword} isMessageAllowed={true} isPasswordEyeDisplayed={true} />}
              </div>
            </div>
            <div className="w-[387px]">
              <div>Confirm password</div>
              {<InputBox type={"password"} addUserMessage={setConfirmPassword} isMessageAllowed={true} isPasswordEyeDisplayed={true} />}
            </div>
            <div className="flex justify-center w-[387px]">
              <button
                className="flex h-12 items-center justify-center gap-2 rounded-md border border-transparent bg-primary px-6 py-3 text-lg font-medium text-white hover:bg-primaryhover focus:outline-none focus:ring-2 focus:ring-primaryhover focus:ring-offset-2"
                onClick={handleChangePassword}
              >
                <RocketIcon />
                <div>Continue</div>
              </button>
            </div>
          </div>
          <p className="mt-3 text-center text-sm text-grey-100">
            Already have an account?
            <Link
              to={"/login"}
              className="pl-1 font-medium text-primary hover:text-primaryhover"
            >
              Login
            </Link>
          </p>
        </div>
        <LoginAside />
        <ToastContainer />
      </div>
    </>
  );
}
