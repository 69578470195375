import { useAuth } from "../../context/AuthContext";

export function ProfilePicture({
  dimensions = 40,
  inChat = false,
  kuration = false,
  shadow = true,
}: {
  dimensions?: number;
  inChat?: boolean;
  kuration?: boolean;
  shadow?: boolean;
}) {
  return (
    <div
      className={`mr-2 flex h-[${dimensions}px] w-[${dimensions}px] items-center justify-center rounded-full  text-xl  ${inChat
          ? "bg-dark text-white"
          : `  text-dark ${shadow ? "shadow-lg" : ""}`
        }`}
      style={{ height: dimensions + "px", width: dimensions + "px" }}
    >
      {kuration ? (
        <KurationImage dimensions={dimensions} />
      ) : (
        <UserPicture dimensions={dimensions} />
      )}
    </div>
  );
}
function KurationImage({ dimensions }: { dimensions: number }) {
  return (
    <img
      className={`bg-[#111827] h-[${dimensions}px] w-[${dimensions}px] rounded-full`}
      height={dimensions}
      width={dimensions}
      src="/logo-sm-chat.svg"
      alt="Kuration"
    />
  );
}
function UserPicture({ dimensions }: { dimensions: number }) {
  const { user } = useAuth();
  return user?.photoURL ? (
    <img
      className="rounded-full"
      src={user.photoURL}
      alt={`${user.displayName || user.email}`}
      height={dimensions}
      width={dimensions}
    />
  ) : (
    <span className={`m-0 p-0`}>
      {user?.displayName?.at(0) || user?.email?.at(0)}
    </span>
  );
}
