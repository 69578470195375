import { useAuth } from "../../context/AuthContext";
import { RocketIcon } from "src/components/svgs/RocketIcon";
import useUserCredits from "src/hooks/useUserCredits";
import useAllChats from "src/hooks/useAllChats";
import { useState } from "react";
import { loadCheckout } from "src/components/utils";
import Spinner from "src/components/elements/Spinner";

export default function CreditPage() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const { userCredits } = useUserCredits(user?.uid ?? null);
  const { data: userChats } = useAllChats(
    user?.uid ?? null,
  );

  function handleUpgrade() {
    loadCheckout(user?.uid ?? "", setLoading);
  }

  return (
    <div className="flex flex-col bg-white gap-y-[48px] w-[534px]">
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-200 bg-opacity-50">
          <Spinner text="Loading" onWhiteBackground />
        </div>
      )}
      <div className="flex flex-col gap-y-2">
        <div className="text-5xl">Credit</div>
        <div className="text-2xl text-grey-100">Check usage and top-up your account</div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col">
          <p className="text-2xl">Credits</p>
          <hr />
          <div className="flex py-6 justify-between items-center">
            <div className="flex flex-col gap-y-4">
              <div className="text-purple text-4xl">
                {!!userCredits
                  ? `${Intl.NumberFormat("en", { notation: "compact" }).format(
                    userCredits.col_credits,
                  )}`
                  : "..."}{" "}
              </div>
              <div className="text-gray-400">Credits left</div>
            </div>
            <button
              className="flex h-12 items-center justify-center gap-1.5 rounded-md  bg-primary px-6 text-base leading-[19.2px] text-white hover:bg-purpleHover"
              onClick={handleUpgrade}
            >
              <RocketIcon />
              <div>Upgrade</div>
            </button>
          </div>
        </div>
        <div className="flex flex-col">
          <p className="text-2xl">Usage History</p>
          <hr />
        </div>
        <div className="flex flex-col gap-y-16 justify-start mb-4">
          <div className="flex mt-10">
            <div className="flex gap-x-4 items-center">
              <div className="text-4xl">{userChats?.length}</div>
              <div className="text-gray-400">Total chats</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

