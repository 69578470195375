import { useState } from "react";
import CountryIcon from "../../components/svgs/Country";
import IndustryIcon from "../../components/svgs/Industry";
import { FilterRow } from "./ManualFilter";
import QuestionMarkIcon from "src/components/svgs/QuestionMark";
import { LoadSearchResults } from "./SearchResults";
import { Fields } from "src/models/company";
import { IconButton } from "src/components/elements/IconButton";
import FilterIcon from "src/components/svgs/Filter";
import { CreateAndSendMessage } from "src/models/data";

export default function ManualScreen({
  createAndSendMessage,
}: {
  createAndSendMessage: CreateAndSendMessage;
}) {
  const [selectedFields, setSelectedFields] = useState<Fields>({
    industry: [],
    country: [],
  });
  function unselectAll(field: keyof Fields) {
    setSelectedFields((prev) => ({
      ...prev,
      [field]: [],
    }));
  }
  function toggleFieldSelect(field: keyof Fields, value: string) {
    if (selectedFields[field].includes(value)) {
      setSelectedFields((prev) => ({
        ...prev,
        [field]: prev[field].filter((f) => f !== value),
      }));
    } else {
      setSelectedFields((prev) => ({
        ...prev,
        [field]: Array.from(new Set([...prev[field], value])),
      }));
    }
  }
  async function handleSearchSave() {
    createAndSendMessage(
      JSON.stringify(selectedFields),
      null,
      true,
      "manual_search",
      "Filters Selected 🔎",
    );
  }
  const isFilterSelected =
    selectedFields.country.length > 0 || selectedFields.industry.length > 0;
  return (
    <div className="flex flex-col flex-grow overflow-auto overflow-y-auto transition-transform duration-300">
      {/* <div className="flex  items-center gap-3 rounded-md bg-grey-600 p-4  md:p-6">
        <div>
          <InfoIcon />
        </div>
        <div className="text-sm font-normal text-grey-200 md:text-base">
          Manually select the settings you want to use to start searching for
          companies and creating a table.
        </div>
      </div> */}
      <div className="flex flex-col gap-4 mt-0">
        <div className=" text-base text-black md:text-xl">Customize the filters to your taste.</div>
        <div className=" text-base text-black md:text-xl">Filter:</div>
      </div>
      <div className="mb-4 mt-1.5 flex w-full flex-col gap-1">
        <FilterRow
          unselectAll={unselectAll}
          toggleFieldSelect={toggleFieldSelect}
          name="Industry"
          field="industry"
          icon={<IndustryIcon />}
          selectedFields={selectedFields["industry"]}
        />
        <FilterRow
          unselectAll={unselectAll}
          toggleFieldSelect={toggleFieldSelect}
          name="Country"
          field="country"
          icon={<CountryIcon />}
          selectedFields={selectedFields["country"]}
        />
      </div>
      <div className=" rounded-md p-2">
        {isFilterSelected ? (
          <LoadSearchResults selectedFields={selectedFields} insideChat />
        ) : (
          <div className="mt-12 flex items-center justify-center gap-2">
            <div>
              <QuestionMarkIcon className="fill-black" />
            </div>
            <div>Please select a filter to narrow down your search</div>
          </div>
        )}{" "}
      </div>
      {isFilterSelected && (
        <div className="py-3">
          <div className="flex items-center justify-center">
            <IconButton
              onClick={() => {
                handleSearchSave();
              }}
              variant="fill"
              icon={<FilterIcon className="stroke-white" />}
              text={"Apply settings"}
            />
          </div>
        </div>
      )}
    </div>
  );
}
