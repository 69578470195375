import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { FilterFormField } from "src/components/preCuratedLists/FilterFields";
import { useAuth } from "src/context/AuthContext";

export const useSearchQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

function jsonParser(input: any) {
  try {
    return JSON.parse(input);
  } catch (error) {
    return null;
  }
}

export const useListFilterQuery = () => {
  const urlSearchParams = useSearchQuery();
  const filterEncoded = urlSearchParams.get("filter");
  const filterDecoded = decodeURIComponent(filterEncoded || "");
  const filterParsed = jsonParser(filterDecoded) || [];
  return filterParsed as Array<FilterFormField>;
};

export const useShowCostQuery = () => {
  const { user } = useAuth();
  const isAdmin = [
    "r7dgefT2ejMB2glLs8WEBequFpd2",
    "e3uolWgr4sXkzS214ugN59RrSqo1",
  ].includes(user?.uid || "");
  const urlSearchParams = useSearchQuery();
  const showCost = urlSearchParams.get("showCost") === "true";
  return isAdmin && showCost;
};
