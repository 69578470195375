import { NoChatOpened } from "./NoChatOpened";
import { useManualMode } from "../../hooks/useManualMode";
import { useTutorialMode } from "../../hooks/useTutorialMode";
import { CreateAndSendMessage } from "src/models/data";
interface Props {
  createAndSendMessage: CreateAndSendMessage;
}
export default function ChatToolbar({
  createAndSendMessage,
}: Props) {
  const { tutorialOpen } = useTutorialMode();
  const { manualModeOpen } = useManualMode();
  return (
    <div
      className={`flex flex-grow flex-col bg-bg-1 px-5 md:p-6 md:pb-[29px] ${
        !tutorialOpen ? "pt-auto h-full" : ""
      }`}
    >
      <div
        className={`${!tutorialOpen && !manualModeOpen ? "mt-auto md:mt-0" : ""}`}
      >
        <NoChatOpened createAndSendMessage={createAndSendMessage} />
      </div>
    </div>
  );
}
