import * as React from "react";
const WalletIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.25 7H5.25C5.05109 7 4.86032 6.92098 4.71967 6.78033C4.57902 6.63968 4.5 6.44891 4.5 6.25C4.5 6.05109 4.57902 5.86032 4.71967 5.71967C4.86032 5.57902 5.05109 5.5 5.25 5.5H18C18.1989 5.5 18.3897 5.42098 18.5303 5.28033C18.671 5.13968 18.75 4.94891 18.75 4.75C18.75 4.55109 18.671 4.36032 18.5303 4.21967C18.3897 4.07902 18.1989 4 18 4H5.25C4.65326 4 4.08097 4.23705 3.65901 4.65901C3.23705 5.08097 3 5.65326 3 6.25V18.25C3 18.8467 3.23705 19.419 3.65901 19.841C4.08097 20.2629 4.65326 20.5 5.25 20.5H20.25C20.6478 20.5 21.0294 20.342 21.3107 20.0607C21.592 19.7794 21.75 19.3978 21.75 19V8.5C21.75 8.10218 21.592 7.72064 21.3107 7.43934C21.0294 7.15804 20.6478 7 20.25 7ZM20.25 19H5.25C5.05109 19 4.86032 18.921 4.71967 18.7803C4.57902 18.6397 4.5 18.4489 4.5 18.25V8.37156C4.74082 8.45693 4.9945 8.50037 5.25 8.5H20.25V19ZM15.75 13.375C15.75 13.1525 15.816 12.935 15.9396 12.75C16.0632 12.565 16.2389 12.4208 16.4445 12.3356C16.65 12.2505 16.8762 12.2282 17.0945 12.2716C17.3127 12.315 17.5132 12.4222 17.6705 12.5795C17.8278 12.7368 17.935 12.9373 17.9784 13.1555C18.0218 13.3738 17.9995 13.6 17.9144 13.8055C17.8292 14.0111 17.685 14.1868 17.5 14.3104C17.315 14.434 17.0975 14.5 16.875 14.5C16.5766 14.5 16.2905 14.3815 16.0795 14.1705C15.8685 13.9595 15.75 13.6734 15.75 13.375Z"
      fill={props.fill || "#111010"}
    />
  </svg>
);
export default WalletIcon;
