const BriefcaseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path 
      d="M4 3h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2h-16c-1.1 0-2-.9-2-2v-12c0-1.1.9-2 2-2zm0 2v3h16v-3h-16zm0 7v3h16v-3h-16zm6-5h4c.55 0 1 .45 1 1s-.45 1-1 1h-4c-.55 0-1-.45-1-1s.45-1 1-1z" 
      fill="#03BE42" 
      strokeWidth="1" // Added strokeWidth to match UploadIcon
    />
  </svg>
);

  export {  
    BriefcaseIcon, 
  };