import * as React from "react";
const TableIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.57261 4V19.7909M20.0012 8.57143H4.00119M19.9989 17.712L19.9875 6.28343C19.9869 5.67762 19.7458 5.09682 19.3172 4.66866C18.8886 4.2405 18.3076 4 17.7018 4H6.27319C5.69653 3.99982 5.14111 4.21761 4.71827 4.60971C4.29544 5.00181 4.03643 5.53925 3.99319 6.11429L3.98633 6.288L3.99776 17.7166C3.99836 18.3224 4.23945 18.9032 4.66803 19.3313C5.09662 19.7595 5.67766 20 6.28347 20H17.712C18.2887 20.0002 18.8441 19.7824 19.267 19.3903C19.6898 18.9982 19.9488 18.4607 19.992 17.8857L19.9989 17.712Z"
      stroke="#111010"
      strokeWidth={1.14286}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default TableIcon;
