import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useAuth } from "src/context/AuthContext";
import { PreCuratedListItem } from "src/models/preCuratedList";
import backend_services from "src/services/backend_service";

export function usePreCuratedListDetail() {
  const { user } = useAuth();
  const { listId } = useParams();
  return useQuery({
    queryKey: ["pre_curated_list_detail", listId],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return (
        user &&
        backend_services
          .fetchProtectedData(
            `/pre_curated_lists/${listId}`,
            user?.getIdToken(),
          )
          .then((res) => res.json() as Promise<PreCuratedListItem>)
      );
    },
  });
}

export function usePreCuratedCompanies({
  pageNum,
  listFilter,
}: {
  pageNum: number;
  listFilter: any[];
}) {
  const { user } = useAuth();
  const { listId } = useParams();
  return useQuery({
    queryKey: [
      "pre_curated_list_items",
      listId,
      pageNum,
      JSON.stringify(listFilter),
    ],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return (
        user &&
        backend_services
          .fetchProtectedData(
            `/pre_curated_lists/${listId}/items?page_number=${pageNum}&page_size=100`,
            user?.getIdToken(),
            undefined,
            "POST",
            { filters: listFilter },
          )
          .then((res) => res.json())
      );
    },
  });
}
