import { useState } from "react";
import { twMerge } from "tailwind-merge";
import BackIcon from "../svgs/Back";
import { IconButton } from "../elements/IconButton";
import { CloudArrowUpIcon } from "@heroicons/react/24/outline";
import backend_services from "src/services/backend_service";
import { useParams } from "react-router-dom";
import { useAuth } from "src/context/AuthContext";
import { SimpleSpinner } from "../elements/Spinner";

interface Cell {
  editable: boolean;
  placeholder: string;
  text: string;
}

export function RowsAdder({
  setAddRowsModalOpen,
}: {
  setAddRowsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { chatId } = useParams();
  const { user } = useAuth();
  const [uploadLoading, setUploadLoading] = useState(false);
  const [cols, setCols] = useState<Cell[]>([
    { text: "Company Name", editable: true, placeholder: "" },
    { text: "Website Url", editable: true, placeholder: "" },
  ]);
  const [rows, setRows] = useState([
    [
      { text: "", editable: true, placeholder: "name of company..." },
      { text: "", editable: true, placeholder: "company website..." },
    ],
  ]);
  function handleColumnAdd() {
    setCols((cols) => [
      ...cols,
      { text: "", editable: true, placeholder: "new column" },
    ]);
    setRows((rows) => [
      ...rows.map((row) => [
        ...row,
        { text: "", editable: true, placeholder: "new column value..." },
      ]),
    ]);
  }
  function handleRowAdd() {
    const newRow: Cell[] = [];
    cols.forEach((col) => {
      newRow.push({
        text: "",
        editable: true,
        placeholder: `${col.text || col.placeholder} value...`,
      });
    });
    setRows((rows) => [...rows, newRow]);
  }
  function updateColText(colIndex: number, text: string) {
    const newCols = [...cols];
    newCols[colIndex].text = text;
    setCols(newCols);
  }
  function updateRowText(rowIndex: number, colIndex: number, text: string) {
    const newRows = [...rows];
    newRows[rowIndex][colIndex].text = text;

    setRows(newRows);
  }
  async function uploadList(list: Array<{ [key: string]: string }>) {
    if (!user || !chatId) {
      return;
    }
    setUploadLoading(true);
    const res = await backend_services
      .fetchProtectedData(
        `/chat/${chatId}/initial_list`,
        user.getIdToken(),
        undefined,
        "POST",
        { user_list: list },
      )
      .then((res) => res.json())
      .finally(() => {
        setUploadLoading(false);
      });
    console.log(res);
  }
  function handleUpload() {
    const listToUpload: Array<{ [key: string]: string }> = [];
    rows.forEach((row) => {
      const uploadRow: { [key: string]: string } = {};
      row.forEach((cell, cellIndex) => {
        const columnText = cols[cellIndex].text;
        if (columnText && columnText.length > 0) {
          uploadRow[columnText] = cell.text;
        }
      });
      // by now all cells should be filled
      // but if all cells are empty -> means full row is empty
      // -> we should not include it in upload
      if (
        !Object.values(uploadRow).every((cellValue) => cellValue.length === 0)
      ) {
        listToUpload.push(uploadRow);
      }
    });
    // console.log(listToUpload);
    uploadList(listToUpload);
  }
  function handleBack() {
    setAddRowsModalOpen(false);
  }

  return (
    <div
      className="max-h-screen overflow-auto rounded-xl bg-white p-4"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Head
        handleBack={handleBack}
        handleUpload={handleUpload}
        uploadLoading={uploadLoading}
      />
      <Body
        cols={cols}
        rows={rows}
        handleColumnAdd={handleColumnAdd}
        handleRowAdd={handleRowAdd}
        updateColText={updateColText}
        updateRowText={updateRowText}
      />
    </div>
  );
}
function Head({
  uploadLoading,
  handleBack,
  handleUpload,
}: {
  uploadLoading: boolean;
  handleBack: () => void;
  handleUpload: () => void;
}) {
  return (
    <div className="flex w-full items-center gap-2  border-b border-grey-400 pb-3 text-lg">
      <div>
        <button
          type="button"
          title="Close company add form"
          className="flex h-[42px] w-[42px] items-center justify-center rounded-md transition-all duration-300 hover:bg-lightpurple"
          onClick={() => {
            handleBack();
          }}
        >
          <BackIcon />
        </button>
      </div>
      <div className="pl-1">Add new companies</div>
      <div className="flex-1"></div>
      <IconButton
        icon={
          uploadLoading ? (
            <SimpleSpinner radius={20} overrideClasses="border-primary" />
          ) : (
            <CloudArrowUpIcon width={20} />
          )
        }
        text={"Upload companies"}
        variant="outline"
        onClick={() => {
          handleUpload();
        }}
        overrideClasses="max-w-max border-none"
      />
    </div>
  );
}
function Body({
  cols,
  rows,
  handleColumnAdd,
  handleRowAdd,
  updateColText,
  updateRowText,
}: {
  cols: Cell[];
  rows: Cell[][];
  handleColumnAdd: () => void;
  handleRowAdd: () => void;
  updateColText: (index: number, text: string) => void;
  updateRowText: (rowIndex: number, colIndex: number, text: string) => void;
}) {
  const className =
    "px-3 py-3 border-separate placeholder:text-gray-200 hover:bg-purpleHover focus-visible:bg-purpleHover bg-primary  outline-none font-normal hover:font-";
  return (
    <div className="mt-4 flex min-h-[30dvh] w-full flex-col items-center justify-center gap-4">
      <table
        className={`relative max-w-full border-collapse border-b border-primary pb-4 text-left text-sm font-light transition-all duration-300`}
      >
        <thead className="z-10   text-white   ">
          <tr className="">
            {cols.map((cell, index) => {
              return (
                <th
                  key={`col-${index}`}
                  className="m-0 overflow-clip  border-none p-0  outline-none transition-all duration-300 first:rounded-tl-lg"
                >
                  <input
                    className={twMerge(className, "")}
                    value={cell.text}
                    onChange={(e) => {
                      updateColText(index, e.target.value);
                    }}
                    placeholder={cell.placeholder}
                  />
                </th>
              );
            })}
            <th
              className=" m-0 overflow-clip   border-none p-0 outline-none  last:rounded-tr-lg"
              onClick={() => {
                handleColumnAdd();
              }}
            >
              <button className={twMerge(className, "")}>+ New column</button>
            </th>
          </tr>
        </thead>
        <tbody className="">
          {rows.map((row, rIndex) => {
            return (
              <tr key={`row-${rIndex}`} className="">
                {row.map((cell, cIndex) => {
                  return (
                    <td>
                      <input
                        className={
                          "w-full rounded border-none bg-transparent px-2 py-3 text-gray-700 outline-none transition-all duration-300 hover:bg-lightpurple"
                        }
                        value={cell.text}
                        onChange={(e) => {
                          updateRowText(rIndex, cIndex, e.target.value);
                        }}
                        placeholder={cell.placeholder}
                      />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <button
        onClick={() => {
          handleRowAdd();
        }}
        className={
          "max-w-full rounded border-none bg-transparent px-2 py-3 text-gray-700 outline-none transition-all duration-300 hover:bg-lightpurple focus:bg-lightpurple"
        }
      >
        + New row
      </button>
    </div>
  );
}
