import { ReactElement } from "react";
import { twMerge } from "tailwind-merge";

export function IconBox({
  icon,
  isPrimary = false,
  overrideClasses,
  onClick
}: {
  icon: ReactElement;
  isPrimary?: boolean;
  overrideClasses?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}) {
  return (
    <div onClick={onClick}
      className={twMerge("flex justify-center rounded-md h-12 w-12 items-center ", `${isPrimary ? "bg-primary" : "border border-primary"}`, overrideClasses)}
    >
      <div>{icon}</div>
    </div>
  );
}
