import { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  getFirestore,
  orderBy,
} from "firebase/firestore";
import firebase_app from "../firebase/config";
import { ChatUpdate } from "../models/data";
import { useParams } from "react-router-dom";

const useChatUpdates = () => {
  const [data, setData] = useState<ChatUpdate[]>([]);
  const { chatId } = useParams();
  useEffect(() => {
    if (!chatId) {
      setData([]);
      return;
    }
    // Create a reference to the Firestore collection
    const updatesQuery = query(
      collection(getFirestore(firebase_app), `/chats/${chatId}/updates`),
      orderBy("timestamp"),
    );

    // Set up a snapshot listener to listen for changes
    const unsubscribe = onSnapshot(
      updatesQuery,
      (snapshot) => {
        const updates: ChatUpdate[] = [];
        snapshot.forEach((updateDoc) => {
          updates.push(updateDoc.data() as ChatUpdate);
        });
        setData(updates);
      },
      (error) => {
        console.log("ERROR OCCURRED");
        console.error(error);
      },
    );

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [chatId]);

  return data;
};

export default useChatUpdates;
