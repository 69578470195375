const getBackendURL = () => {
  const urls: { [key: string]: string | undefined } = {
    staging: process.env.REACT_APP_STAGING_BACKEND_URL,
    production: process.env.REACT_APP_BACKEND_URL,
    development: "http://127.0.0.1:8000",
    test: "http://127.0.0.1:8000", // Optional URL for 'test' environment
  };

  console.log(process.env.REACT_APP_STAGING_BACKEND_URL);
  console.log(process.env.REACT_APP_NODE_ENV);
  return (
    urls[process.env.REACT_APP_NODE_ENV || "development"] || urls.development
  );
};

const backend_url = `${getBackendURL()}/api`;
const ws_url = `${window.location.protocol === "https:" ? backend_url.replace("https", "wss") : backend_url.replace("http", "ws")}/chat`;

// const backend_url = process.env.NODE_ENV === 'development' ? '127.0.0.1:8000/api' : '/api'; //staging

async function fetchProtectedData(
  slug: string,
  tokenPromise: Promise<string> | undefined,
  abortSignal?: AbortSignal,
  method: "GET" | "POST" | "DELETE" = "GET",
  requestBody: { [key: string]: any } | undefined = undefined,
) {
  const token = await tokenPromise;
  let url;
  url = `${backend_url}${slug}`;

  return fetch(url, {
    signal: abortSignal,
    method: method, // "GET" or "POST", "PUT", etc.
    headers: {
      Authorization: `Bearer ${token}`,
      ...(requestBody ? { "Content-Type": "application/json" } : {}),
    },
    ...(requestBody ? { body: JSON.stringify(requestBody) } : {}),
  });
}

const backend_services = { fetchProtectedData, backend_url, ws_url };
export default backend_services;
