import * as React from "react";
const FullScreenIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.25 20.75H6.90375C7.08125 20.75 7.22958 20.81 7.34875 20.93C7.46792 21.05 7.52792 21.1987 7.52875 21.3762C7.52875 21.5529 7.46875 21.7013 7.34875 21.8213C7.22958 21.9404 7.08125 22 6.90375 22H3.01C2.72333 22 2.48333 21.9033 2.29 21.71C2.09667 21.5167 2 21.2767 2 20.99V17.0962C2 16.9187 2.06 16.7704 2.18 16.6512C2.3 16.5312 2.44875 16.4712 2.62625 16.4712C2.80292 16.4712 2.95125 16.5312 3.07125 16.6512C3.19042 16.7704 3.25 16.9187 3.25 17.0962V20.75ZM20.775 20.75V17.0962C20.775 16.9187 20.8346 16.7704 20.9538 16.6512C21.0729 16.5321 21.2217 16.4721 21.4 16.4712C21.5775 16.4712 21.7258 16.5312 21.845 16.6512C21.9642 16.7704 22.0237 16.9187 22.0237 17.0962V20.99C22.0237 21.2767 21.9271 21.5167 21.7338 21.71C21.5404 21.9033 21.3008 22 21.015 22H17.12C16.9433 22 16.795 21.94 16.675 21.82C16.555 21.7 16.495 21.5513 16.495 21.3738C16.495 21.1971 16.555 21.0487 16.675 20.9287C16.795 20.8096 16.9433 20.75 17.12 20.75H20.775ZM3.25 3.25V6.90375C3.25 7.08125 3.19 7.22958 3.07 7.34875C2.95 7.46792 2.80125 7.52792 2.62375 7.52875C2.44708 7.52875 2.29875 7.46875 2.17875 7.34875C2.05958 7.22958 2 7.08125 2 6.90375V3.01C2 2.72333 2.09667 2.48333 2.29 2.29C2.48333 2.09667 2.72333 2 3.01 2H6.90375C7.08125 2 7.22958 2.06 7.34875 2.18C7.46875 2.3 7.52875 2.44875 7.52875 2.62625C7.52875 2.80292 7.46875 2.95125 7.34875 3.07125C7.22958 3.19042 7.08125 3.25 6.90375 3.25H3.25ZM20.775 3.25H17.12C16.9433 3.25 16.795 3.19 16.675 3.07C16.555 2.95 16.495 2.80125 16.495 2.62375C16.495 2.44708 16.555 2.29875 16.675 2.17875C16.795 2.05958 16.9433 2 17.12 2H21.015C21.3008 2 21.5404 2.09667 21.7338 2.29C21.9271 2.48333 22.0237 2.72333 22.0237 3.01V6.90375C22.0237 7.08125 21.9638 7.22958 21.8438 7.34875C21.7237 7.46875 21.5754 7.52875 21.3987 7.52875C21.2212 7.52875 21.0729 7.46875 20.9538 7.34875C20.8337 7.22958 20.7737 7.08125 20.7737 6.90375L20.775 3.25Z"
      fill="black"
      className={props.className}
    />
  </svg>
);
export default FullScreenIcon;
