// StateContext.js
import React, { createContext, useContext, useState } from "react";
import { AppState, ChatType } from "../models/appState";
import { DpeState } from "src/models/Dpe";
const StateContext = createContext<AppState>({
  chatOpen: true,
  setChatOpen: () => {},

  filtersOpen: true,
  setFiltersOpen: () => {},

  sidebarOpen: window.innerWidth > 770,
  setSidebarOpen: () => {},
  chatType: "refine",
  setChatType: (value) => {},

  dpeState: { isOpen: false, type: "null_dpe",  },
  setDpeState: () => {},
});

export const StateProvider = ({ children }: { children: any }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [chatOpen, setChatOpen] = useState(true);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [chatType, setChatType] = useState<ChatType>("refine");

  const [dpeState, setDpeState] = useState<DpeState>({
    isOpen: false,
    type: "null_dpe"
  });

  return (
    <StateContext.Provider
      value={{
        chatOpen,
        setChatOpen,
        sidebarOpen,
        setSidebarOpen,
        chatType,
        setChatType,
        filtersOpen,
        setFiltersOpen,
        dpeState,
        setDpeState,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => {
  return useContext(StateContext);
};
