import { InitialListItem } from "src/models/InitialList";
import GoogleMapPin from "../svgs/GoogleMapPin";
import UploadIcon from "../svgs/Upload";
import HandIcon from "../svgs/Hand";
import { BriefcaseIcon } from "../svgs/BriefcaseIcon";
import { NewspaperIcon } from "../svgs/NewpaperIcon";
import SearchIcon from "../svgs/Search";
import { OfficeBuildingIcon } from "../svgs/OfficeIcon";

const items: InitialListItem[] = [
  {
    _id: "6",
    name: "csvFileUploader",
    description: "Upload CSV file",
    subtitle: "Import and curate existing lists",
    icon: <UploadIcon />,
  },
  {
    _id: "1",
    name: "googleMap",
    description: "Target customers in specific areas",
    subtitle: "Extract businesses using GoogleMaps",
    icon: <GoogleMapPin />,
  },
  {
    _id: "2",
    name: "salesNavSearch",
    description: "Scrape Linkedin Sales Navigator",
    subtitle: "Export long list of leads from a search URL",
    icon: (
      <img
        src="https://uploads-ssl.webflow.com/6697d10a891d3b0b8f8a6628/6698c438c5e7f16548da414d_linkedin-sales-navigator.jpg"
        alt="LinkedIn"
        className="h-12 w-12" // Updated for responsiveness
      />
    ),
  },
  {
    _id: "3",
    name: "GoogleJob",
    description: "Scrape JobBoards",
    subtitle: "Find companies currently hiring for specific roles",
    icon: <NewspaperIcon />,
  },
  {
    _id: "4",
    name: "parentCompanyLookup",
    description: "Uncover potential business connections",
    subtitle: "Find all subsidiaries of parent companies",
    icon: <BriefcaseIcon />,
  },
  {
    _id: "5",
    name: "companyDetailsLookup",
    description: "Get deeper insights into businesses",
    subtitle: "Enrich details of a long list companies",
    icon: <OfficeBuildingIcon/>,
  },
 
  {
    _id: "7",
    name: "startManually",
    description: "Start with standard filters",
    subtitle: "get list based on location and industry",
    icon: <HandIcon />,
  },
  {
    _id: "8",
    name: "peopleFinder",
    description: "Identify individuals by their roles",
    subtitle: "Find People from company websites",
    icon: <SearchIcon/>,
  },
];

export default items;
