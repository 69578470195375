import React from "react";
import Stripe from "stripe";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  onSnapshot,
  addDoc,
  getFirestore,
  collection,
  getDoc,
  doc,
  setDoc,
} from "firebase/firestore";
import firebase_app from "../firebase/config";
import { loadStripe } from "@stripe/stripe-js";
import { Company, Field, FinalListItem } from "../models/data";
import backend_services from "../services/backend_service";
import { SearchCompany } from "../models/company";
import {
  Column,
  CustomToolColumn,
  KurationToolColumn,
} from "src/hooks/useChatTable";
import Papa from "papaparse";

export const numberFormatter = Intl.NumberFormat("en", { notation: "compact" });
export async function saveChatName(
  chatId: string,
  newName: string,
  tokenPromise: Promise<string>,
) {
  const result = await backend_services.fetchProtectedData(
    `/chat_name/${chatId}`,
    tokenPromise,
    undefined,
    "POST",
    { name: newName },
  );
  if (!result || result.status !== 200) {
    console.log("something went wrong", result);
  }
}

export const scrollToElement = ({
  containerRef,
}: {
  containerRef: React.MutableRefObject<null | HTMLDivElement>;
}) => {
  if (containerRef?.current) {
    containerRef.current.scrollIntoView({ behavior: "smooth" });
  } else {
  }
};
export function normalizeCompanies(
  companies: Array<Record<string, string>> | SearchCompany[],
): Company[] {
  console.log("normalizing companies");
  return companies.map((company) => {
    const newCompany: Company = [];
    for (const key in company) {
      if (Object.prototype.hasOwnProperty.call(company, key)) {
        if (key !== "_id") {
          const value = company[key];
          const item: Field = {
            data_field: key,
            value: value,
            source: "",
            type: "user_added",
          };
          newCompany.push(item);
        }
      }
    }
    return newCompany;
  });
}
interface UniqueColumnsOptions {
  ignoreCustomColumns?: boolean;
  ignoreUserAddedOrAiFields?: boolean;
}
export function getUniqueColumns(
  companies: Array<Company>,
  options?: UniqueColumnsOptions,
) {
  const columns = new Set<string>();
  companies.forEach((company) => {
    company.forEach((attribute) => {
      if (
        options?.ignoreCustomColumns &&
        attribute.type === "custom_column" &&
        attribute.custom_column_id
      ) {
        // ignore special columns
      } else if (
        options?.ignoreUserAddedOrAiFields &&
        (attribute.type === "ai_generated" || attribute.type === "user_added")
      ) {
        // ignore ai generated or user added columns
      } else {
        columns.add(attribute.data_field.toLowerCase());
      }
    });
  });
  return Array.from(columns);
}

export function reshapeCompanyData(companyData: FinalListItem[]) {
  const results: Company[] = [];
  companyData?.forEach((company) => {
    if (typeof company.final_research_results == "object") {
      results.push(company.final_research_results);
    }
  });
  return results;
}
function reorderCompanyList(companyList: Company[]) {
  const data = companyList.map((company) => {
    return company.reduce(
      (dict, field) => {
        dict[field.data_field.toLowerCase()] = field.value || "";
        return dict;
      },
      {} as { [key: string]: string },
    );
  });
  return data;
}
function setUpDownload({
  data,
  orderedColumns,
  chatId,
}: {
  data: Array<{ [key: string]: string }>;
  orderedColumns: string[];
  chatId: string;
}) {
  const csv = Papa.unparse(data, {
    columns: orderedColumns, // Only include these fields
    quotes: true, // Optionally wrap each field with quotes
    quoteChar: '"',
    escapeChar: '"', // Escape quotes properly
  });
  const downloadLink = document.createElement("a");
  const blob = new Blob(["\ufeff", csv]);
  const url = URL.createObjectURL(blob);
  downloadLink.href = url;
  downloadLink.download = `KurationList_${chatId}.csv`; //Name the file here
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}
function getUniqueColNames(colsList: Column[]) {
  let displayNames = colsList.map((col) =>
    getColumnDisplayName(col).toLowerCase(),
  );
  colsList.forEach((col, index) => {
    const isNameMoreThanOnce =
      displayNames.filter(
        (name) => name === getColumnDisplayName(col).toLowerCase(),
      ).length > 1;
    if (isNameMoreThanOnce) {
      let instance = 0;
      displayNames = displayNames.map((name) => {
        if (name === getColumnDisplayName(col).toLowerCase()) {
          instance = instance + 1;
          return getColumnDisplayName(col).toLowerCase() + "_" + instance;
        }
        return name;
      });
    }
  });
  return displayNames;
}
export function downloadList({
  companyList,
  currentOpenChatId = "",
  columns,
}: {
  companyList: Company[];
  columns?: Column[];
  currentOpenChatId: string | undefined;
}) {
  try {
    if (columns) {
      // convert list of fields to dicts
      const data = reorderCompanyList(companyList);
      // order columns, tools columns at end

      const nonToolCols = columns.filter(
        (a) => a.type === "normal" || a.type === "research_plan_column",
      );
      const reorderedNonToolColumnNames = reorderColumns(
        nonToolCols.map((c) => c.data_field),
      );
      const reorderedNonToolColumns = reorderedNonToolColumnNames.map((c) =>
        nonToolCols.find((nc) => nc.data_field === c) || nonToolCols[0]
      );
      const toolCols = columns.filter(
        (b) => b.type === "custom_tool_column" || b.type === "tool_column",
      );
      const orderedColumns = [...reorderedNonToolColumns, ...toolCols];
      // convert to human names -> might have duplicates
      const uniqueOrderedKeys = getUniqueColNames(orderedColumns);
      
      // convert each column id to column display name for human readability
      const namedData: { [key: string]: string }[] = data.map((c) => {
        const newDict: { [key: string]: string } = {};
        orderedColumns.forEach((col, index) => {
          newDict[uniqueOrderedKeys[index]] = c[col.data_field.toLowerCase()];
        });
        return newDict;
      });
      if (process.env.NODE_ENV === "development"){
        console.log({uniqueOrderedKeys, namedData});
      }
      setUpDownload({
        data: namedData,
        orderedColumns: uniqueOrderedKeys,
        chatId: currentOpenChatId,
      });
    } else {
      const uniqueColumns = getUniqueColumns(companyList);
      const data = reorderCompanyList(companyList);

      const orderedColumns = uniqueColumns
        .map((c) => c.toLowerCase())
        .sort(
          (a, b) => Number(a.includes("_##_")) - Number(b.includes("_##_")),
        );
      setUpDownload({ data, orderedColumns, chatId: currentOpenChatId });
    }
  } catch (error) {
    console.log(error);
  }
}
export function createPortalLink(
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
) {
  setLoading(true);
  // firebaseApp is object created using initializeApp()
  // may need to change server location
  const functions = getFunctions(firebase_app, "us-central1");
  const createPortalLink = httpsCallable(
    functions,
    "ext-firestore-stripe-payments-createPortalLink",
  );

  // request Stripe to create a portal link, and redirect user there
  createPortalLink({
    returnUrl: window.location.origin, // can set this to a custom page
  })
    .then((result) => {
      setLoading(false);
      window.location.assign(
        (result?.data as { url: string })?.url || window.location.href,
      );
    })
    .catch((error) => {
      // handle error
      setLoading(false);
      console.log(error);
    });
}
export const loadCheckout = async (
  userId: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  // console.log(userId);
  setLoading(true);
  const sessionsRef = collection(
    getFirestore(firebase_app),
    `users/${userId}/checkout_sessions`,
  );
  const docRef = await addDoc(sessionsRef, {
    price: "price_1PAq1JL3Cb1dCmacKEWvvogt",
    success_url: window.location.href,
    cancel_url: window.location.href,
  });

  onSnapshot(docRef, async (snap) => {
    const { error, sessionId } = snap.data() as any;

    if (error) {
      alert(`An error occurred: ${error.message}`);
      setLoading(false);
    }

    if (sessionId) {
      const stripe = await loadStripe(
        process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? "",
      );
      // setLoading(false);
      stripe?.redirectToCheckout({ sessionId });
    }
  });
};

export function timeSince(date: Date) {
  const formatter = new Intl.RelativeTimeFormat(undefined, {
    numeric: "auto",
  });

  const DIVISIONS: { amount: number; name: Intl.RelativeTimeFormatUnit }[] = [
    // { amount: 60, name: "seconds" },
    // { amount: 60, name: "minutes" },
    // { amount: 24, name: "hours" },
    { amount: 7, name: "days" },
    { amount: 4.34524, name: "weeks" },
    { amount: 12, name: "months" },
    { amount: Number.POSITIVE_INFINITY, name: "years" },
  ];

  function formatTimeAgo(date: Date) {
    let duration =
      ((date as any) - (new Date() as any)) / (1000 * 60 * 60 * 24);

    for (let i = 0; i < DIVISIONS.length; i++) {
      const division = DIVISIONS[i];
      if (Math.abs(duration) < division.amount) {
        return formatter.format(Math.round(duration), division.name);
      }
      duration /= division.amount;
    }
  }
  return formatTimeAgo(date);
}

export function sentenceCase(content: string, splitBy: "_" | " " = " ") {
  return String(content)
    ?.split(splitBy)
    .map((s) => s && s.charAt(0)?.toUpperCase() + s?.substring(1))
    .join(" ");
}
export function isCompanyNameColumn(col: string) {
  if (col.toLowerCase() === "company_name") return true;
  if (col.toLowerCase() === "company") return true;
  if (col.toLowerCase().includes("company name")) return true;
  if (col.toLowerCase().includes("name")) return true;
  return false;
}
const levenshteinDistance = (s: string, t: string) => {
  if (!s.length) return t.length;
  if (!t.length) return s.length;
  const arr = [];
  for (let i = 0; i <= t.length; i++) {
    arr[i] = [i];
    for (let j = 1; j <= s.length; j++) {
      arr[i][j] =
        i === 0
          ? j
          : Math.min(
              arr[i - 1][j] + 1,
              arr[i][j - 1] + 1,
              arr[i - 1][j - 1] + (s[j - 1] === t[i - 1] ? 0 : 1),
            );
    }
  }
  return arr[t.length][s.length];
};

export function getCompanyNameColumn(columns: string[]) {
  return columns
    .map((c) => ({
      col_name: c,
      distance: levenshteinDistance(c, "company_name"),
    }))
    .sort((a, b) => a.distance - b.distance)
    .at(0)?.col_name;
}
export function getIndustryColumn(columns: string[]) {
  return columns
    .filter((col) => {
      if (col.toLowerCase() === "industry") return true;
      return false;
    })
    .at(0);
}
export function getLocationColumn(columns: string[]) {
  return columns
    .filter((col) => {
      if (col.toLowerCase() === "location") return true;
      return false;
    })
    .at(0);
}
export function removeItemOnce(arr: string[], value: string) {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}
export function fetchTools(tokenPromise: Promise<string>) {
  return backend_services
    .fetchProtectedData(`/tools`, tokenPromise, undefined, "GET")
    .then((res) => res.json());
}
export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};
export function reorderColumns(columns: string[]) {
  const localColumns = [...columns];
  const domainColumn = getDomainNameColumn(localColumns);
  const companyNameColumn = getCompanyNameColumn(localColumns);
  // const industryColumn = getIndustryColumn(localColumns);
  // const locationColumn = getLocationColumn(localColumns);
  
  if (domainColumn) {
    removeItemOnce(localColumns, domainColumn);
    localColumns.unshift(domainColumn);
  }
  if (companyNameColumn) {
    removeItemOnce(localColumns, companyNameColumn);
    localColumns.unshift(companyNameColumn);
  }
  return localColumns;
}
export function domainName(columns: string[], queries: string[]) {
  let name = null;
  for (let index = 0; index < queries.length; index++) {
    const element = queries[index];
    name = columns.find((col) => col.toLowerCase().includes(element));
    if (name) {
      break;
    }
  }
  return name;
}
export function getDomainNameColumn(columns: string[]) {
  return domainName(columns, [
    "website",
    "linkedin_url",
    "link",
    "url",
    "domain",
    "online_presence",
    "online presence",
  ]);
}

export function getColumnDisplayName(col: Column) {
  if (col.display_name){
    return col.display_name;
  }
  return col.type === "normal" || col.type === "research_plan_column"
    ? sentenceCase(col.data_field, "_")
    : getToolDisplay(col);
}
export function getToolDisplay(col: KurationToolColumn | CustomToolColumn) {
  return col.type === "custom_tool_column"
    ? col.custom_tool.fields.name
    : col.tool.display_field
      ? col.column_data[col.tool.display_field]
      : col.tool.name;
}

export const getSubscription = async (userId: string) => {
  const userRef = doc(getFirestore(firebase_app), `users/${userId}`);
  const stripeId = (await getDoc(userRef)).get("stripeId");
  const stripe = new Stripe(`${process.env.REACT_APP_STRIPE_SECRET_KEY}`);
  // Retrieve all subscriptions for the customer
  const subscriptions = await stripe.subscriptions.list({
    customer: stripeId,
  });

  if (subscriptions.data.length > 0) {
    const subscription = subscriptions.data[0];
    // Loop through all items in the subscription
    const item = subscription.items.data[0];
    // Fetch the price associated with the subscription item
    const price = await stripe.prices.retrieve(item.price.id);
    // Fetch the product associated with the price
    const productId =
      typeof price.product === "string" ? price.product : price.product.id;
    const product = await stripe.products.retrieve(productId);
    return {
      id: subscription.id,
      name: product.name,
      description: product.description,
    };
  } else {
    return {
      id: "",
      name: "Free",
      description: "50 complimentary AI-enabled queries",
    };
  }
};

export const cancelSubscription = async (id: string) => {
  const stripe = new Stripe(`${process.env.REACT_APP_STRIPE_SECRET_KEY}`);
  return await stripe.subscriptions.cancel(id);
};

export const updateUserInfo = async (userId: string, data: any) => {
  try {
    const docRef = doc(getFirestore(firebase_app), `users/${userId}`);
    await setDoc(docRef, data);
  } catch (error) {
    console.log(error);
  }
};
