import { SimpleSpinner } from "src/components/elements/Spinner";
import { FilterTags } from "./FilterTags";
import { FilterCard } from "./FilterCard";
import { CustomTool, KurationTool, Tool } from "src/models/tools";
import { CustomFiltersList } from "./CustomFiltersList";
import { useState } from "react";

export const FiltersMain = ({
  isLoading,
  error,
  tools,
  retryTools,
  customTools,
  customToolsLoading,
  setSelectedTool,
  setShowCreateCustomFilter,
}: {
  isLoading: boolean;
  error: any;
  tools: KurationTool[];
  customToolsLoading: boolean;
  customTools: CustomTool[];
  retryTools: () => void;
  setShowCreateCustomFilter: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedTool: React.Dispatch<React.SetStateAction<Tool | null>>;
}) => {
  const [filter, setFilter] = useState<"all" | "kuration" | "custom">("all");
  return (
    <>
      <div className="inline-flex  flex-col items-start justify-start gap-[33px] border-b border-neutral-300 bg-white px-6 py-9">
        <div className="  text-lg font-normal text-black">Enrichment library</div>
        <div className="inline-flex  items-center justify-start gap-3">
          <div className="flex flex-wrap items-center justify-start gap-4">
            <div className=" text-neutral-400">Jump to:</div>
            <FilterTags
              selected={filter === "all"}
              text="All"
              onClick={() => {
                setFilter("all");
              }}
            />
            <FilterTags
              selected={filter === "kuration"}
              onClick={() => {
                setFilter("kuration");
              }}
              text="Kuration AI enrichment library"
            />
            <FilterTags
              selected={filter === "custom"}
              onClick={() => {
                setFilter("custom");
              }}
              withStars
              text="Your own filters"
            />
          </div>
        </div>
      </div>
      <div className="w-full p-6">
        {(filter === "all" || filter === "custom") && (
          <CustomFiltersList
            customTools={customTools}
            customToolsLoading={customToolsLoading}
            setShowCreateCustomFilter={setShowCreateCustomFilter}
            setSelectedTool={setSelectedTool}
          />
        )}

        {(filter === "all" || filter === "kuration") && (
          <div className="flex flex-col gap-6">
            <div className="flex w-full items-center justify-between gap-4">
              <div className="text-lg font-normal text-neutral-900">
                Kuration AI enrichment library ready made filters
              </div>
              <div className="flex min-w-max items-center justify-center gap-2.5 rounded bg-indigo-50 px-3 py-2.5">
                <div className="  text-neutral-900">
                  {tools?.length | 0} filters
                </div>
              </div>
            </div>
            {isLoading && <SimpleSpinner />}
            {(!!error || !Array.isArray(tools)) && (
              <div
                onClick={() => {
                  retryTools();
                }}
              >
                Retry
              </div>
            )}
            <div className="grid grid-cols-2 gap-4">
              {(tools as KurationTool[])?.map((t) => (
                <div
                  key={t.id}
                  onClick={() => {
                    setSelectedTool(t);
                  }}
                >
                  <FilterCard name={t.name} iconUrl={t.icon} />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
