import * as React from "react";
const UploadIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.75 17.25C4.94891 17.25 5.13968 17.329 5.28033 17.4697C5.42098 17.6103 5.5 17.8011 5.5 18V20.25C5.5 20.388 5.612 20.5 5.75 20.5H18.25C18.3163 20.5 18.3799 20.4737 18.4268 20.4268C18.4737 20.3799 18.5 20.3163 18.5 20.25V18C18.5 17.8011 18.579 17.6103 18.7197 17.4697C18.8603 17.329 19.0511 17.25 19.25 17.25C19.4489 17.25 19.6397 17.329 19.7803 17.4697C19.921 17.6103 20 17.8011 20 18V20.25C20 20.7141 19.8156 21.1592 19.4874 21.4874C19.1592 21.8156 18.7141 22 18.25 22H5.75C5.28587 22 4.84075 21.8156 4.51256 21.4874C4.18437 21.1592 4 20.7141 4 20.25V18C4 17.8011 4.07902 17.6103 4.21967 17.4697C4.36032 17.329 4.55109 17.25 4.75 17.25Z"
      fill="#03BE42"
    />
    <path
      d="M18.7802 9.52951C18.7107 9.59918 18.6281 9.65444 18.5371 9.69215C18.4462 9.72986 18.3487 9.74927 18.2502 9.74927C18.1518 9.74927 18.0543 9.72986 17.9634 9.69215C17.8724 9.65444 17.7898 9.59918 17.7202 9.52951L12.7502 4.56051L12.7502 16.7495C12.7502 16.9484 12.6712 17.1392 12.5306 17.2798C12.3899 17.4205 12.1992 17.4995 12.0002 17.4995C11.8013 17.4995 11.6106 17.4205 11.4699 17.2798C11.3293 17.1392 11.2502 16.9484 11.2502 16.7495L11.2502 4.56051L6.28025 9.52951C6.13968 9.67008 5.94904 9.74904 5.75025 9.74904C5.55146 9.74904 5.36081 9.67008 5.22025 9.52951C5.07968 9.38895 5.00071 9.1983 5.00071 8.99951C5.00071 8.80072 5.07968 8.61008 5.22025 8.46951L11.4702 2.21951C11.5398 2.14985 11.6224 2.09458 11.7134 2.05687C11.8043 2.01917 11.9018 1.99976 12.0002 1.99976C12.0987 1.99976 12.1962 2.01917 12.2871 2.05687C12.3781 2.09458 12.4607 2.14985 12.5302 2.21951L18.7802 8.46951C18.8499 8.53908 18.9052 8.62169 18.9429 8.71264C18.9806 8.80358 19 8.90106 19 8.99951C19 9.09796 18.9806 9.19545 18.9429 9.28639C18.9052 9.37733 18.8499 9.45995 18.7802 9.52951Z"
      fill="#03BE42"
    />
  </svg>
);
export default UploadIcon;
