import { DpeRunLogsState, LangSmithRun } from "src/models/Dpe";
import { twMerge } from "tailwind-merge";
import { HumanOutput } from "./HumanOutput";
import { sentenceCase } from "src/components/utils";
import { DocumentIcon, WrenchIcon } from "@heroicons/react/24/outline";
import StarsIcon from "src/components/svgs/Stars";
import { MarkDown } from "src/components/elements/Markdown";

export function LangSmithDpe({ dpeState }: { dpeState: DpeRunLogsState }) {
  return (
    <div>
      {dpeState.type === "langsmith_dpe" &&
        dpeState.run_logs?.map((step) => {
          return (
            <div key={step.trace_id} className="mt-3 flex flex-col gap-4">
              {step.runs.map((run, index) => {
                return (
                  <div
                    key={run.start_time.toString()}
                    className={twMerge(
                      "max-h-96 max-w-full overflow-auto rounded bg-grey-600 p-3",
                      `${index > 0 ? "ml-3" : ""}`,
                    )}
                  >
                    <div className="my-3 flex items-center gap-2">
                      {runTypeIcon(run)}
                      <span className="text-xl">
                        {run.name === "User_Custom_Research_Tool"
                          ? "Kuration AI result:"
                          : run.name === "ChatOpenAI"
                            ? "Kuration AI"
                            : sentenceCase(run.name, "_")}
                      </span>
                    </div>
                    {run.input && (
                      <div className="my-4 ">
                        {Object.keys(run.input).map((k) => {
                          return (
                            <div className=" text-gray-800">
                              <strong className="font-mono">{k}:</strong>{" "}
                              <MarkDown>{run.input?.[k]}</MarkDown>
                            </div>
                          );
                        })}
                      </div>
                    )}

                    {run.output && (
                      <div className=" text-gray-700">
                        <HumanOutput run={run} />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
}

function runTypeIcon(run: LangSmithRun) {
  if (run.type === "tool") {
    return <WrenchIcon width={23} className="text-primary" />;
  }
  if (run.type === "retriever") {
    return <DocumentIcon width={23} className="text-primary" />;
  }
  return <StarsIcon />;
}
