import * as React from "react";
const IndustryIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 9H21V7.5C21 7.10218 20.842 6.72064 20.5607 6.43934C20.2794 6.15804 19.8978 6 19.5 6H4.5C4.10218 6 3.72064 6.15804 3.43934 6.43934C3.15804 6.72064 3 7.10218 3 7.5V9ZM3 10.5V18C3 18.3978 3.15804 18.7794 3.43934 19.0607C3.72064 19.342 4.10218 19.5 4.5 19.5H19.5C19.8978 19.5 20.2794 19.342 20.5607 19.0607C20.842 18.7794 21 18.3978 21 18V10.5H3ZM4.5 4.5H19.5C20.2957 4.5 21.0587 4.81607 21.6213 5.37868C22.1839 5.94129 22.5 6.70435 22.5 7.5V18C22.5 18.7956 22.1839 19.5587 21.6213 20.1213C21.0587 20.6839 20.2957 21 19.5 21H4.5C3.70435 21 2.94129 20.6839 2.37868 20.1213C1.81607 19.5587 1.5 18.7956 1.5 18V7.5C1.5 6.70435 1.81607 5.94129 2.37868 5.37868C2.94129 4.81607 3.70435 4.5 4.5 4.5Z"
      fill="#111010"
    />
    <path
      d="M9 3V4.5H15V3H9ZM9 1.5H15C15.3978 1.5 15.7794 1.65804 16.0607 1.93934C16.342 2.22064 16.5 2.60218 16.5 3V4.5C16.5 4.89782 16.342 5.27936 16.0607 5.56066C15.7794 5.84196 15.3978 6 15 6H9C8.60218 6 8.22064 5.84196 7.93934 5.56066C7.65804 5.27936 7.5 4.89782 7.5 4.5V3C7.5 2.60218 7.65804 2.22064 7.93934 1.93934C8.22064 1.65804 8.60218 1.5 9 1.5Z"
      fill="#111010"
    />
  </svg>
);
export default IndustryIcon;
