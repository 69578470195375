import { Link } from "react-router-dom";
import { MarkDown } from "src/components/elements/Markdown";
import { LangSmithRun } from "src/models/Dpe";

export function HumanOutput({ run }: { run: LangSmithRun }) {
  if (!run.output) {
    return <div></div>;
  }
  if (Object.hasOwn(run.output, "output")) {
    if (Object.hasOwn(run.output.output, "log")) {
      return (
        <div>
          <MarkDown>{run.output?.output?.["log"]}</MarkDown>
        </div>
      );
    }
    if (typeof run.output.output === "string") {
      return (
        <div>
          <MarkDown>{run.output?.output}</MarkDown>
        </div>
      );
    }
  }
  if (Array.isArray(run.output.documents)) {
    return (
      <div>
        <div>
          {run.output.documents.length} relevant results found from several
          pages on the web
        </div>
        <ul>
          {run.output.documents.map((d, index) => {
            return (
              <li key={`${index}${d.source || d.timestamp}`}>
                <div>
                  <Link className="underline" to={d.source}>
                    {d.title}
                  </Link>
                  - <span>{d.description}</span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
  if (
    Array.isArray(run.output.generations) &&
    run.output.generations[0]?.text
  ) {
    return (
      <div>
        <MarkDown>{run.output.generations[0]?.text}</MarkDown>
      </div>
    );
  }
  if (Array.isArray(run.output)) {
    if (
      typeof run.output[0] === "string" ||
      typeof run.output[0] === "number"
    ) {
      return (
        <ul>
          {run.output.map((i, index) => {
            return <li key={`${index}${i}`}>{i}</li>;
          })}
        </ul>
      );
    } else if (typeof run.output[0] === "object") {
      <ul>
        {run.output.map((item, index) => {
          return (
            <li key={`${index}${item}`}>
              {Object.keys(item).map((k) => {
                return (
                  <div key={k}>
                    <strong className="font-mono">{k}:</strong>{" "}
                    {JSON.stringify(run.output?.[k])}
                  </div>
                );
              })}
            </li>
          );
        })}
      </ul>;
    }
  }
  return (
    <div>
      {Object.keys(run.output).map((k) => {
        return (
          <div key={k}>
            <strong className="font-mono">{k}:</strong>{" "}
            {JSON.stringify(run.output?.[k])}
          </div>
        );
      })}
    </div>
  );
}
