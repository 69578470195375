import Sidebar from "../sidebar/Sidebar";
import Navbar from "src/components/NavBar";
import { twMerge } from "tailwind-merge";
import { PreCuratedLists } from "src/components/preCuratedLists/PreCuratedLists";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "src/context/AuthContext";
import FullScreenLottie from "src/lotties/FullScreenLottie";


export default function PreCuratedListPage() {
  const { appInitialized, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!appInitialized) {
      return;
    }
    if (!user) {
      navigate("/login");
    }
    if (!user?.emailVerified) {
      navigate("/verify");
    }
  }, [user, appInitialized, navigate]);

  if (!appInitialized) {
    return <FullScreenLottie />;
    // return <Spinner text="Kuration.io is loading..." />;
  }

  return (
    <div className="flex flex-col lg:flex-row bg-white">
      <div className={twMerge("sticky top-0 z-40 w-full  bg-dark md:hidden")}>
        <Navbar />
      </div>
      <Sidebar />
      <div
        className={`flex h-[calc(100vh_-_78px)] flex-1 flex-grow overflow-auto bg-bg-2 pr-0 pt-4 transition-all duration-300  ease-out md:h-screen md:p-9 md:pl-10 md:pr-0`}
      >
        <div className="max-h-full w-full overflow-auto">
            <PreCuratedLists />
        </div>
      </div>
    </div >
  );
}
