"use client";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Input from "./Input";
import FormAction from "./FormAction";
import { GoogleAuthProvider, OAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { loginFields } from "./formFields";
import { useAuth } from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import firebase_app from "../../firebase/config";
import { addUserToLoop, createDataForNewUser } from "./api-loop_os";
import Spinner from "../elements/Spinner";

const fields = loginFields;
let fieldsState: { [key: string]: any } = {};
fields.forEach((field) => (fieldsState[field.name] = ""));

export default function Login() {
  const [loginState, setLoginState] = useState(fieldsState);
  const { signIn, user } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [timer, setTimer] = useState<number | null>(null);
  useEffect(() => {
    if (!(error.length > 1)) {
      return;
    }
    const timeId = setTimeout(() => {
      setError("");
    }, 5000);
    setTimer(() => timeId as unknown as number);
    return () => {
      timer && clearTimeout(timer);
    };
  }, [error, timer]);

  const handleChange = (e: ChangeEvent) => {
    setLoginState({
      ...loginState,
      [(e.target as HTMLInputElement).name]: (e.target as HTMLInputElement)
        .value,
    });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await authenticateUser();
  };
  const handleGoogleAuth = async () => {
    try {
      const userCred = await signInWithPopup(
        getAuth(firebase_app),
        new GoogleAuthProvider(),
      );
      if (
        userCred.user.metadata.creationTime ===
        userCred.user.metadata.lastSignInTime
      ) {
        addUserToLoop(
          createDataForNewUser(userCred.user.email || ""),
          userCred.user.getIdToken(),
        );
      }
      navigate("/");
    } catch (error) {
      setError(
        "Login Failed. Try again with correct credentials or Sign Up with new account.",
      );
    }
  };

  const handleMicrosoftAuth = async () => {
    try {
      const userCred = await signInWithPopup(
        getAuth(firebase_app),
        new OAuthProvider("microsoft.com"),
      );
      if (
        userCred.user.metadata.creationTime ===
        userCred.user.metadata.lastSignInTime
      ) {
        addUserToLoop(
          createDataForNewUser(userCred.user.email || ""),
          userCred.user.getIdToken(),
        );
      }
      navigate("/");
    } catch (error) {
      setError(
        "Login Failed. Try again with correct credentials or Sign Up with new account.",
      );
    }
  };

  //Handle Login API Integration here
  const authenticateUser = async () => {
    setLoading(true);
    try {
      await signIn(loginState["email"], loginState["password"]);
      navigate("/");
    } catch (error) {
      setLoading(false);
      setError(
        "Login Failed. Try again with correct credentials or Sign Up with new account.",
      );
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex w-full flex-col md:max-w-sm ">
        {loading && (
          <Spinner
            text="Logging In."
            styleClasses={["backdrop-blur-sm z-20"]}
            onWhiteBackground
          />
        )}
        {user && (
          <p className="my-5  text-center text-sm  text-grey-100">
            Signed In as: {user.displayName || user.email}{" "}
            <Link
              to={"/"}
              className="font-medium text-primary hover:text-primaryhover"
            >
              {"Go to Home"}
            </Link>
          </p>
        )}
        <div className="flex flex-col gap-3">
          <div className="flex justify-center">
            <button className="w-5/6 " onClick={handleGoogleAuth}>
              <div className="flex items-center justify-center gap-2 rounded-lg border border-grey-400 p-2">
                <img
                  height={30}
                  width={30}
                  src={"google.svg"}
                  alt="Login with Google"
                />
                <div className="w-[152.06px] text-start">Sign In with Google</div>
              </div>
            </button>
          </div>

          <div className="flex justify-center">
            <button className="w-5/6 " onClick={handleMicrosoftAuth}>
              <div className="flex items-center justify-center gap-2 rounded-lg border border-grey-400 p-2">
                <img
                  height={30}
                  width={30}
                  src={"https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg"}
                  alt="Login with Microsoft"
                />
                <div>Sign In with Microsoft</div>
              </div>
            </button>
          </div>
        </div>

        <div className="relative my-4 flex h-3 w-full items-center justify-center text-center text-base text-black">
          <div className="absolute top-1/2 z-0 h-[1px] w-5/6 bg-grey-500"></div>
          <div className="z-10 h-6 bg-white px-2.5 leading-[27.4px]">OR</div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="flex flex-col">
            {fields.map((field) => (
              <Input
                key={field.id}
                handleChange={handleChange}
                value={loginState[field.name]}
                labelText={field.labelText}
                labelFor={field.labelFor}
                id={field.id}
                name={field.name}
                type={field.type}
                isRequired={field.isRequired}
                placeholder={field.placeholder}
                customClass={""}
              />
            ))}
          </div>
          <p>{error}</p>
          <FormAction action="submit" text="Continue" type="Button" />
        </form>
      </div>
    </div>
  );
}
