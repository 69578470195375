import { ArrowPathIcon } from "@heroicons/react/24/outline";
import {
  useQuery,
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult,
} from "@tanstack/react-query";
import { useState, useEffect } from "react";
import Pagination from "src/components/elements/Pagination";
import { SimpleSpinner } from "src/components/elements/Spinner";
import {
  numberFormatter,
  normalizeCompanies,
  downloadList,
} from "src/components/utils";
import { useAuth } from "src/context/AuthContext";
import { Fields, SearchCompany } from "src/models/company";
import backend_services from "src/services/backend_service";
import { CompanyListTable } from "../../components/table/CompanyListTable";
import TableView from "src/components/table/TableView";
import { useParams } from "react-router-dom";

export const LoadSearchResults = ({
  selectedFields,
  insideChat = false,
}: {
  selectedFields: Fields;
  insideChat?: boolean;
}) => {
  const { chatId } = useParams();
  const { user } = useAuth();
  const [page, setPage] = useState(1);
  const PAGE_SIZE = 100;

  useEffect(() => {
    setPage(1);
  }, [selectedFields]);

  const { data: countData } = useQuery({
    queryKey: ["company_count", JSON.stringify(selectedFields)],
    refetchOnWindowFocus: false,
    queryFn: () => {
      if (!user) {
        return;
      }
      return backend_services
        .fetchProtectedData(
          `/company_counts`,
          user?.getIdToken(),
          undefined,
          "POST",
          selectedFields,
        )
        .then((res) => res.json());
    },
  });
  const myCountData = countData as { document_count: number } | undefined;

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["company_list", JSON.stringify(selectedFields), page],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return (
        user &&
        backend_services
          .fetchProtectedData(
            `/company_list?page_number=${page}&page_size=${PAGE_SIZE}`,
            user?.getIdToken(),
            undefined,
            "POST",
            selectedFields,
          )
          .then((res) => res.json())
      );
    },
  });
  async function handleDownload() {
    if (!user) return;

    const downloadSearchResult = (await backend_services
      .fetchProtectedData(
        `/company_list?page_number=${page}&page_size=1000`,
        user?.getIdToken(),
        undefined,
        "POST",
        selectedFields,
      )
      .then((res) => res.json())) as
      | { companies: Array<SearchCompany>; next: boolean }
      | undefined;
    if (!downloadSearchResult) return;
    const companies = normalizeCompanies(downloadSearchResult?.companies || []);

    downloadList({
      companyList: companies,
      currentOpenChatId: chatId,
      
    });
  }
  const dataLength = myCountData?.document_count || 0;
  const totalPages = Math.ceil(dataLength / PAGE_SIZE);
  const hasNextPage = page < totalPages;
  const handleNextPage = () => {
    if (hasNextPage) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const searchResult = data as
    | { companies: Array<SearchCompany>; next: boolean }
    | undefined;

  if (insideChat) {
    return (
      <div>
        <div className="flex items-center justify-between gap-2 py-2">
          <div>
            Found{" "}
            {myCountData?.document_count !== null &&
              numberFormatter.format(myCountData?.document_count || 0)}{" "}
            companies
          </div>
          <Pagination
            currentPage={page}
            hasNextPage={(myCountData?.document_count || 0) > page * PAGE_SIZE}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            totalPages={totalPages}
          />
        </div>
        <SearchCompanyLoader
          error={error}
          isLoading={isLoading}
          refetch={refetch}
          searchCompanies={searchResult?.companies}
          insideChat={insideChat}
        />
      </div>
    );
  } else {
    const companies = normalizeCompanies(searchResult?.companies || []);

    return (
      <TableView
        type="search"
        companies={companies}
        companiesLoading={isLoading}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
        hasNextPage={hasNextPage}
        page={page}
        rowCount={dataLength}
        totalPages={totalPages}
        pageSize={PAGE_SIZE}
        downloadHandler={() => handleDownload()}
      />
    );
  }
};

export const SearchCompanyLoader = ({
  isLoading,
  error,
  refetch,
  searchCompanies,
  insideChat,
  compact = true,
  height = "small",
}: {
  isLoading: boolean;
  error: unknown;
  compact?: boolean;
  height?: "small" | "full";
  searchCompanies: Array<SearchCompany> | undefined;
  insideChat: boolean;
  refetch: (
    options?: (RefetchOptions & RefetchQueryFilters) | undefined,
  ) => Promise<QueryObserverResult<any, unknown>>;
}) => {
  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center gap-2">
        <SimpleSpinner radius={20} overrideClasses="border-black" /> loading
        companies
      </div>
    );
  } else if (error || !Array.isArray(searchCompanies)) {
    return (
      <div className="flex h-full items-center justify-center ">
        <button
          className="flex items-center gap-2"
          onClick={() => {
            refetch();
          }}
        >
          <ArrowPathIcon width={20} className="text-black" />
          <div className="text-black">Retry</div>
        </button>
      </div>
    );
  }

  const companies = normalizeCompanies(searchCompanies);

  return (
    <CompanyListTable
      companies={companies}
      compact={compact}
      height={height}
      startIndex={1}
    />
  );
};
