import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import React, { useState, useRef, ReactNode } from "react";
type AccordionProps = {
  title: ReactNode;
  content?: ReactNode;
};
const AccordionItem = ({ title, content = null }: AccordionProps) => {
  const [isOpened, setOpened] = useState<boolean>(false);
  const [height, setHeight] = useState<string>("0px");
  const contentElement = useRef<HTMLDivElement>(null);

  const HandleOpening = () => {
    setOpened(!isOpened);
    setHeight(!isOpened ? `${contentElement?.current?.scrollHeight}px` : "0px");
  };
  return (
    <div className="border-b-[1px] border-dark border-opacity-50 last-of-type:border-0">
      <div
        onClick={HandleOpening}
        className={`flex items-center justify-between p-6 hover:cursor-pointer ${
          isOpened ? "border-b-[1px] border-dark border-opacity-25" : ""
        }`}
      >
        <h4 className="font-semibold">{title}</h4>
        {content ? (
          isOpened ? (
            <ChevronUpIcon height={20} />
          ) : (
            <ChevronDownIcon height={20} />
          )
        ) : null}
      </div>
      <div
        ref={contentElement}
        style={{ height: height }}
        className="overflow-hidden transition-all duration-200"
      >
        {content && <div className="p-6 ">{content}</div>}
      </div>
    </div>
  );
};

export default AccordionItem;
