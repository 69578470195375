import { useSearchParams } from "react-router-dom"

export const useTutorialMode = () => {
    const [urlParams, setUrlParams] = useSearchParams();
    const key = 'tutorial'
    const tutorialOpen = urlParams.get(key) === 'true';
    const setTutorialOpen = (value: boolean) => {
        if (value) {
            setUrlParams((params) => {
                params.set(key, 'true');
                return params
            })
        } else {

            setUrlParams((params) => {
                params.delete(key);
                return params
            })
        }
    }
    return { tutorialOpen, setTutorialOpen };
}