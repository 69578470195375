import { FilterFormField } from "src/components/preCuratedLists/FilterFields";
import { getColumnDisplayName } from "src/components/utils";
import { Column } from "src/hooks/useChatTable";

export function parseFilterForm(filterForm: FilterFormField[], columnList: Column[]) {
    const filterToSend: { [key: string]: any }[] = [];
    filterForm.forEach((f) => {
        if (f.type === "greaterThan" || f.type === "lessThan") {
            f.value = parseInt(f.value as string);
        }
        if (f.type === "exists") {
            f.value = f.value === "true";
        }
        const filterCopy: any = { ...f };

        const col = columnList?.find((c) => c.data_field === f.key);

        if (col) {
            filterCopy.display = getColumnDisplayName(col);
        }
        filterToSend.push(filterCopy);
    });
    return filterToSend;
}