export function LoginAside() {
  return (
    <div className="hidden max-w-[55%]  flex-grow flex-col items-center justify-center gap-14 bg-black p-12 px-20 md:flex">
      <img
        src="login-bg.png"
        width={853}
        height={370}
        alt="logos for various companies"
      />
      <div className="w-full">
        <div className="flex gap-1">
          <div className="h-2 w-2 rounded-full bg-white"></div>
          <div className="h-2 w-2 rounded-full bg-white"></div>
          <div className="h-2 w-12 rounded-full bg-white"></div>
        </div>
        <div className="mt-8 text-3xl font-normal leading-[43px] text-white lg:text-5xl lg:leading-[67.8px]">
          Turn any bit of company info <br></br>into actionable data points
        </div>
        <div className="mt-4 text-2xl font-normal leading-[33.6px] text-grey-400">
          Source, verify, curate, aggregate company information
        </div>
      </div>
    </div>
  );
}
